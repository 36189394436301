
import React, { useEffect, useRef, useState } from 'react';

const NavItem = (props) => {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension());
        }
        window.addEventListener('resize', updateDimension);
        
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize]);

    var [cnt, setCnt] = useState(0);
    var nested = (props.children) ? true : false;

    function mouseEnter(e) {
        if(screenSize.width > 1024){
            var subnav = e.target.parentElement.querySelector(".subNav");
            // var subnavItem = subnav.querySelectorAll(".subNav li");
            var nestWrapper = e.target.parentElement.querySelector(".nest-wrapper");
            // console.log(nestWrapper.offsetHeight)
            // subnavItem.forEach(function(currentElement, index){
            //     setCnt(cnt += currentElement.offsetHeight);
            // });
            // setCnt(nestWrapper.offsetHeight);
            subnav.style.height = (nestWrapper.offsetHeight - 25)+"px";
            subnav.classList.add("shown");
        }
    }
    function mouseLeave(e) {
        if(screenSize.width > 1024){
            var subnav = e.target.parentElement.querySelector(".subNav");

            setCnt(0);
            subnav.style.height = "0px";
            subnav.classList.remove("shown");
        }
    }

    function mouseLeaveInner(e) {
        if(screenSize.width > 1024){
            e.preventDefault();
            e.stopPropagation();
            var subnav = e.target.closest(".subNav");
            setCnt(0);
            subnav.style.height = "0px";
        }
    }

    // console.log(props)
    return(
        <>
        {nested?(
            <li className="nested">
                <a href={props.href}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    >
                    {props.title}

                    {(nested && screenSize.width > 1024) && (
                        <ul className='subNav'onMouseLeave={mouseLeaveInner}>
                            {props.children}
                        </ul>
                    )}
                </a>

                {(nested && screenSize.width <= 1024) && (
                    <ul className='subNav'onMouseLeave={mouseLeaveInner}>
                        {props.children}
                    </ul>
                )}
            </li>
        ):(
            <li>
                <a href={props.href} onClick={props.onClick}>
                    {props.title}
                </a>
            </li>
        )}
        </>
    );
}


export default NavItem;