import React, {useRef, useState, useEffect} from 'react';
import { useParams, useLocation } from 'react-router-dom'
import Loader from '../components/Loader';

import "../assets/styles/PageNotFound.scss";

import envelope from "../assets/images/envelope.png";
import tile from '../assets/images/tile-contact.png'

export default function OptionGenerator() {
    const [loading, setLoading] = useState(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    useEffect(() => {
        setLoading(false);
    });

    function converter(e) {
        e.preventDefault();
        e.stopPropagation();

        var converterInput = document.querySelector("#input");
        
        var converterOutput = "";

        (converterInput.value).split("\n").forEach(val => {
            converterOutput += ("<option value='"+val+"'>"+val+"</option>\n");
        });

        document.querySelector("#output").value = converterOutput;

    }

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <section className='thankyou'>
            <textarea name="" id="input" rows="15" cols="50"></textarea>
            <textarea name="" id="output" rows="15" cols="50" readOnly></textarea>

            <button className='btn btn-black' onClick={(e)=>converter(e)}>Convert</button>
        </section>
        </>
        </>
    );
}