import React, {useState, useEffect} from 'react';
import { Fade } from "react-awesome-reveal";
import { useAppContext } from '../libs/contextLib';
import NavItem from './NavItem';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/bootstrap-unset.scss';

import logo from '../assets/images/telavi.png';
import logoBlue from '../assets/images/telavi-blue.png';
import ClickToCall from './ClickToCall';

export default function Header() {
    const {isPageLight, setIsPageLight, show, setShow} = useAppContext();
    const isShown = (window.location.pathname === "/tech-support");
    const [stickyClass, setStickyClass] = useState('relative');
    const [openNav, setOpenNav] = useState(false);


    // const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => {
        setShow(true)
    };

    function toggleNav(){
        setOpenNav(!openNav);
    }

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => {
          window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
          let windowHeight = window.scrollY;
          windowHeight > 125 ? setStickyClass('fixed') : setStickyClass('relative');
        }
    };

    return(
        <>
        {/* <header className='header-alt'>
            <div className="logo">
                <a href="/">
                    {isPageLight == "none" ? "" : 
                        (isPageLight ? 
                            <img src={logo} alt="" className='light' id="header-logo"/>
                            :
                            <img src={logoBlue} alt="" className='blue' id="header-logo"/>)
                    }
                    
                </a>
            </div>
            <div className='quote ml-3'>
                {!isShown ? 
                    <a href="/tech-support">Talk to an Expert</a>
                    : ""
                }
            </div>
        </header> */}
        <div className="logo-independent">
            <a href="/">
                {isPageLight == "none" ? "" : 
                    (isPageLight ? 
                        <img src={logo} alt="" className='light' id="header-logo"/>
                        :
                        <img src={logoBlue} alt="" className='blue' id="header-logo"/>)
                }
            </a>
        </div>
        <header className={openNav ? "opened" : ""}>
            <div className={openNav ? "nav-button opened" : "nav-button"} onClick={toggleNav}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className={openNav ? "menu mobileNav" : "menu"}>
                <nav>
                    <ul>
                        <NavItem title="Products">
                            <div className="nest-wrapper">
                                <div className="nest-header">
                                    <img src={require("../assets/images/nav-products.png")} alt="" />
                                    <span>Products</span>
                                </div>
                                <div className="nest-items">
                                    <li><a href="/products/roam">Roam</a></li>
                                    <li><a href="/products/bizconnect">BizConnect</a></li>
                                    <li><a href="/products/cxboost">CXBoost</a></li>
                                    <li><a href="/products/click2call">Click2Call</a></li>
                                    <li><a href="/products/link">Link</a></li>
                                </div>
                            </div>
                            {/* </Fade> */}
                        </NavItem>
                        <NavItem title="Industry Solutions" href="/industries-solutions"/>
                        <NavItem href="/media" title="Media"/>
                        {/*&nbsp; */}
                        <NavItem title="Tech Support" href="/tech-support"/>
                        {/* <NavItem title="Click-to-Call" onClick={handleShow}/> */}
                        {/* <NavItem title="Inquire Now" href="/inquire-now"/> */}
                        <div className='quote ml-0'>
                            <a href="/inquire-now">
                                Inquire Now
                            </a>
                        </div>
                        &nbsp;
                        <div className='quote ml-0'>
                            {/* {!isShown ?  */}
                                <a onClick={handleShow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" id="phone"><path fill="#fff" d="M12.2 10c-1.1-.1-1.7 1.4-2.5 1.8C8.4 12.5 6 10 6 10S3.5 7.6 4.1 6.3c.5-.8 2-1.4 1.9-2.5-.1-1-2.3-4.6-3.4-3.6C.2 2.4 0 3.3 0 5.1c-.1 3.1 3.9 7 3.9 7 .4.4 3.9 4 7 3.9 1.8 0 2.7-.2 4.9-2.6 1-1.1-2.5-3.3-3.6-3.4z"></path></svg>
                                    Talk to an Expert</a>
                                {/* : ""
                            } */}
                        </div>
                    </ul>
                </nav>
            </div>
            <div className="mobile-bg" onClick={toggleNav}></div>
        </header>
        <button id="clickToCallCTA" onClick={handleShow}>
            <a>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" id="phone"><path fill="#fff" d="M12.2 10c-1.1-.1-1.7 1.4-2.5 1.8C8.4 12.5 6 10 6 10S3.5 7.6 4.1 6.3c.5-.8 2-1.4 1.9-2.5-.1-1-2.3-4.6-3.4-3.6C.2 2.4 0 3.3 0 5.1c-.1 3.1 3.9 7 3.9 7 .4.4 3.9 4 7 3.9 1.8 0 2.7-.2 4.9-2.6 1-1.1-2.5-3.3-3.6-3.4z"></path></svg>
                Talk to an Expert
            </a>
        </button>
        <Modal size='md' show={show} onHide={handleClose}>
            <div id='c2c'>
                <button id='endCall' className="endCall" onClick={handleClose}>
                    End Call
                </button>
                {/* <iframe src="https://dialpad.telavi.com.ph/" allow='microphone' frameborder="0"></iframe> */}
                {/* <iframe src="https://main.d35633ub29581y.amplifyapp.com" allow='microphone' frameborder="0"></iframe> */}
                <iframe src="https://main.d2l0w0fhx4mk10.amplifyapp.com/" allow='microphone' frameborder="0"></iframe>
            </div>
        </Modal>
        </>
    );
}