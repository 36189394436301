import React, {useRef, useState, useEffect} from 'react';
import { Fade } from "react-awesome-reveal";
import "../assets/styles/Contact.scss";
import tile from '../assets/images/tile-contact.png'
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet-async';
import "react-datepicker/dist/react-datepicker.css";
import referralImage from "../assets/images/referralProgram-newBanner.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ReferralForm(props) {

    const [loading, setLoading] = useState(true);
    const [overlayLoading, setOverlayLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        var els = document.querySelectorAll("input");
        els.forEach(item => {item.onkeydown = ()=>{item.classList.remove("error")}});
        setLoading(false);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        var button = document.querySelector("input[type='submit']");
        setOverlayLoading(true);
        button.setAttribute("disabled", "disabled");
        
        const form = e.target.closest("form");
        const inputs = form.querySelectorAll("input")
        // const emailRegex = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const emailRegex = new RegExp (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const phoneRegex = new RegExp (/^[0-9]{10,11}$/);
        // const phoneRegex = new RegExp (/^09\d{9}$/);
        // const phoneRegex63 = new RegExp (/^639\d{9}$/);
        // const telephoneRegex = new RegExp (/^\d{10}$/);
        inputs.forEach((item)=>item.classList.remove("error"));

        const formData = new FormData(form);
        var validated = true;
        var errMessage = ""

        var data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        // VALIDATION
        // CHECK FOR T&C
        if(document.querySelector("input[name='acceptTNC']:checked") == null){
            validated=false;
            addError("acceptTNC");
            errMessage="Please agree to Terms and Condition";
        }

        // CHECK CONTACT
        if(!phoneRegex.test(data["contactNumber"])/*   && !phoneRegex63.test(data["contactNumber"]) && !telephoneRegex.test(data["contactNumber"]) */){
            validated=false;
            addError("contactNumber");
            errMessage="Invalid Phone Number";
        }

        // CHECK EMAIL
        if(!emailRegex.test(data["email"])){
            validated=false;
            addError("email");
            errMessage="Invalid Email";
        }

        // CHECK FOR EMPTY
        formData.forEach((value, key) => {
            if(key != "middleName"){
                if(value=="" || value=="null"){
                    validated=false;
                    errMessage="Please complete the form";
                    addError(key);
                }
            }
        });

        if(validated){
            var json = JSON.stringify(data);

            const response = await fetch(
                "https://2qxi85kv34.execute-api.ap-southeast-1.amazonaws.com/prod/register", {
                method: 'POST',    
                headers: {
                    'Content-Type': 'application/json'
                },        
                body: json
            });
            const res = await response.json();
    
            if(res.status == "success"){
                // navigate("/case-success")
                navigate("/case-success?referralCode="+res.referralCode);
            }
            else if(res.status == "error"){
                setTimeout(() => {
                    toast.info('You are already registered.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
            else{
                setTimeout(() => {
                    toast.error('Uh oh! Something went wrong, please try again.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
        
        }else{
            toast.error(errMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            setOverlayLoading(false);
            button.removeAttribute("disabled");
        }
    }


    function addError(key) { 
        var el = document.querySelector("input[name='"+key+"']");
        el.classList.add("error")
    }

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <Helmet>
                <title>Telavi - Referral Form</title>
        </Helmet>
        {overlayLoading ? 
            <div className="overlay-loader">
                <Loader/>
            </div>
            :""
        }
        <section className='referral-page split midbanner midbanner-5050 p-0'>
            <div className="content-wrapper">
                <div className="left">
                    <div className="form-header">
                        <img src={tile} className='tile tile-second'/>
                        <div>
                            <h1>Register for Telavi's Referral Program</h1>
                            <h3>Refer your contacts and get them started on their digitization journey with Telavi today!</h3>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="midbanner-img">
                        <img src={referralImage} className=''/>
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="left pt-0">
                    <form className='pt-0'>
                        <input type="text" placeholder='Last Name' name='lastName'/>
                        <input type="text" placeholder='Given Name' name='givenName'/>
                        <input type="text" placeholder='Middle Name (Optional)' name='middleName'/>
                        <input type="number" placeholder='Contact Number (09XX-YYY-ZZZZ) or (XX-YYYY-ZZZZ)' name='contactNumber' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                        <input type="email" placeholder='Email Address' name='email'/>
                        {/* <input type="text" placeholder='Contact Person in Telavi?' name='contactPerson'/> */}
                        <input type="text" placeholder='Present Address' name='address'/>
                        <input type="text" placeholder='Nature of Work' name='natureOfWork'/>
                        {/* <input type="number" placeholder='Referral Code' name='referralCode'/> */}
                        <div className="check-box">
                            <label className="check-container">
                                <input type="checkbox" name='acceptTNC'/>
                                <span className="checkmark"></span>
                                <span>I have read and agree to the &nbsp;<a href="/referral-agreement">Terms and Conditions</a></span>
                            </label>
                        </div>
                        <input type="submit" value="Submit"  className='btn btn-black' onClick={(e)=>{handleSubmit(e)}}/>
                        <a className='signup-link' href="/enduser-form">Already have a code? Sign up here</a>
                    </form>
                </div>
                <div className="right"> 
                    <div className="midbanner-img">
                        <img src={require("../assets/images/partnerProgramBanner-tile.png")} className='partnerProgramTile' />
                        <div className="midbanner-img-text">
                            <h1>
                                Get Started Now!
                            </h1>
                            <ul>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Connect, share, and earn with a vibrant community of go-getters!                
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Join by signing up to receive your unique referral code!
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Start making referrals immediately and earn commissions* on customer referred
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Market Development Fund for achieved targets
                                    </span>
                                </li>
                                <li>
                                    <span className='note'>
                                        * Note: Commissions apply to Telavi Cloud services only (excludes physical devices)
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ToastContainer/>
        </>
        </>
    );
}