import React, { useEffect } from 'react';
import { Switch, Route, Routes, useLocation } from "react-router-dom"
import Footer from "./components/Footer";
import ProdAndServices from "./containers/ProductsAndServices";
import Solutions from "./containers/Solutions";
import Contact from "./containers/Contact";
import Thankyou from "./containers/Thankyou";
import Loader from "./components/Loader";
import FloatingButton from "./components/FloatingButton";
import NewsAndArticles from './containers/NewsAndArticles';
import Article from './containers/Article';
import Product from './containers/Product';
import IndustriesSolutions from './containers/IndustriesSolutions';
import ReferralForm from './containers/ReferralForm';
import RefereeForm from './containers/RefereeForm';
import PartnerProgramDealForm from './containers/PartnerProgramDealForm';
import PartnerProgramSignUpForm from './containers/PartnerProgramSignUpForm';
import ReferralAgreement from './containers/ReferralAgreement';
import OptionGenerator from './containers/OptionGenerator';
import LeadsGen from './containers/LeadsGen';
import ClickToCall from './components/ClickToCall';
const Home = React.lazy(() => import('./containers/Home'));
const Pricing = React.lazy(() => import('./containers/Pricing'));
const PageNotFound = React.lazy(() => import('./containers/PageNotFound'));
const Privacy = React.lazy(() => import('./containers/Privacy'));

export default function Router(props) {
    const location = useLocation();
    useEffect(() => {
        props.reactGA.send({hitType:"pageview", page: location.pathname});
        // console.log('called');
    }, [location]);

    return (		              
        <Routes>								
            <Route path="/" element={<Home/>} />
            {/* <Route path="/productsandservices" element={<ProdAndServices/>} />
            <Route path="/solutions" element={<Solutions/>} />
            <Route path="/pricing" element={<Pricing/>} /> 
            <Route path="/thankyou" element={<Thankyou/>} />*/}
            <Route path="/tech-support" reactGA={props.reactgA} element={<Contact/>} />
            <Route path="/inquire-now" element={<LeadsGen/>} />
            <Route path="/policy" element={<Privacy/>} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/referral-agreement" element={<ReferralAgreement/>} />
            <Route path="/case-success" element={<Thankyou/>} />
            <Route path="/referral-form" element={<ReferralForm/>} />
            <Route path="/enduser-form" element={<RefereeForm/>} />
            {/* <Route path="/partnerProgramDealForm" element={<PartnerProgramDealForm/>} /> */}
            <Route path="/partner-program-signup" element={<PartnerProgramSignUpForm/>} />
            <Route path="/media" element={<NewsAndArticles/>} />
            <Route path="/media/:slug" element={<Article/>} />
            <Route path="/products/:slug" element={<Product/>} />
            <Route path="/industries-solutions" element={<IndustriesSolutions/>} />
            <Route path="/optiongenerator" element={<OptionGenerator/>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>                            
        );
    }

    