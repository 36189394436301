import Delayed from '../libs/Delayed';
import React from 'react';
import { Fade } from "react-awesome-reveal";

import logo from '../assets/images/telavi-blue.png';
import icon_fb from '../assets/images/icon-fb.png';
import icon_ig from '../assets/images/icon-ig.png';
import icon_li from '../assets/images/icon-linkedin.png';

import footer_bg1 from "../assets/images/footer-bg-1.png"
import contact_bg1 from "../assets/images/contact-tile-new.png"
import contact_fig from "../assets/images/contact-figure-new.png"

export default function Footer() {
    const url = window.location.pathname;
    const isShown = (
        window.location.pathname === "/tech-support" ||
        window.location.pathname === "/thankyou" ||
        window.location.pathname === "/inquire-now"
        );
    return(
        
        <Delayed waitBeforeShow={1500}>
        {!isShown && (
            <section className="contact-us">
                <div className="background">
                    <Fade cascade triggerOnce direction='right'  duration={500}><img src={contact_bg1} className='contact-tile' /></Fade>
                    <Fade cascade triggerOnce direction='left'  duration={500} className='right'><img src={contact_fig} /></Fade>
                </div>
                <div className="content-wrapper">
                    <Fade cascade triggerOnce direction='up'  duration={500} delay={500}>
                        <h1 className='title title-orange'>Talk to Telavi</h1>
                        <p>Interested in bringing Telavi's seamless services to your organization? Tell us how we can help you!</p>
                        <a href='/inquire-now' className="btn btn-black mt-5">Contact Us</a>
                    </Fade>
                </div>
            </section>
        )}
        <footer>
            <div className="footer-inner-wrapper">
                <Fade cascade triggerOnce direction='right'  duration={500}> 
                    <div className="logo">
                        <a href="/"><img src={logo} alt="" /></a>
                        {/* <span>Telavi Cloud 2023 @All Rights Reserved.</span> */}
                    </div>
                    {/* <div className="sitemap">
                        <ul>
                            <Fade direction='right'  duration={300}> 
                            <li><a href="/">Home</a></li>
                            <li><a href="/productsandservices">Products and Services</a></li>
                            <li><a href="/solutions">Solutions</a></li>
                            <li><a href="/pricing">Pricing</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/tech-support">Contact Us</a></li>
                           </Fade>
                        </ul>
                    </div> */}
                    {/* <div className="privacy">
                        <ul>

                        <Fade direction='right'  duration={300}> 
                            <li><a href="/privacypolicy">Privacy and Policy</a></li>
                            <li><span>Telavi Cloud 2023 @All Rights Reserved</span></li>
                            <li className='socmed'>
                                <a href=""><img src={icon_fb} alt="" /></a>
                                <a href=""><img src={icon_ig} alt="" /></a>
                                <a href=""><img src={icon_li} alt="" /></a>
                            </li>
                            </Fade>
                        </ul>
                    </div> */}
                    <div className="privacy">
                        <ul>
                            <Fade triggerOnce direction='right'  duration={300}> 
                                <li><a href="/policy">Privacy and Policy</a></li>
                                <li><span>Telavi Cloud 2023 @All Rights Reserved</span></li>
                                <li className='socmed'>
                                    <a href="https://www.facebook.com/TelaviPH/"><img src={icon_fb} alt="" /></a>
                                    {/* <a href=""><img src={icon_ig} alt="" /></a> */}
                                    <a href="https://www.linkedin.com/showcase/telavi-cloud/"><img src={icon_li} alt="" /></a>
                                </li>
                            </Fade>
                        </ul>
                    </div>
                    <div className="footer-contact">
                        <ul>
                            <Fade triggerOnce direction='right'  duration={300}> 
                                <li><h3>Contact Us</h3></li>
                                <li><img src={require("../assets/images/telephone (1).png")} alt="" srcSet="" /> <span>(+632) 89670580</span> </li>
                                <li><img src={require("../assets/images/pin.png")} alt="" srcSet="" /> <span>Gur Lavi Building <br />#220 Wilson Street San Juan City</span> </li>
                                <li><img src={require("../assets/images/email (1).png")} alt="" srcSet="" /> <span>support@telavi.com.ph</span> </li>
                            </Fade>
                        </ul>
                    </div>
                </Fade>
            </div>
            <Fade cascade triggerOnce direction='left'  duration={500} className='footer-bg-wrapper'>
                <img src={footer_bg1} className='footer-bg-1'/>.
            </Fade>
            </footer>
        </Delayed>
    );
}