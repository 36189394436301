import React from 'react';
// import Spinner from "../assets/images/spinner.png";

export default function Loader(){
	return (
        <div className="loader-wrapper">
            <span className='loader'>
            </span>
        </div>
	);	
};