import React, {useRef, useState, useEffect} from 'react';
import Loader from '../components/Loader';
import { Fade, Zoom } from "react-awesome-reveal";
import { useAppContext } from '../libs/contextLib';
import { useParams } from 'react-router';
import "../assets/styles/NewsAndArticles.scss";

import banner1 from "../assets/images/NewsAndArticle-banner.png"
import bannertile from "../assets/images/inner-page-tile.png"

export default function Article() {
    const {isPageLight, setIsPageLight} = useAppContext();
    const [loading, setLoading] = useState(true);
    const params= useParams();

    const [articleContent, setArticleContent] = useState();

    // function htmlToElements(html) {
    //     var template = document.createElement('template');
    //     template.innerHTML = html.trim();
    //     console.log(template.content);
    //     return Array.from(template.content);
    // }

    const getArticleDetails = async (articleTitle) => {
        try {
          const articleFetch = await fetch(`https://api.telavidev.gurlavi.com.ph/cmsadmin/articles/view/${articleTitle}`);
          const article = await articleFetch.json();
          let divElement = document.getElementById('content-wrapper');
      
          //console.log('Article:', article);
      
          if (Array.isArray(article) && article.length > 0) {
            const firstArticle = article[0];
            //console.log(firstArticle);

            // var template = document.createElement('template');
            // template.innerHTML = firstArticle.body.trim();
            // console.log(template.content);

            // const contentElements = template.content;
            // divElement.appendChild(contentElements);
            // console.log(contentElements);
            // setArticleContent(contentElements);
      
            // Check if 'body' property exists and is not null or undefined
            if (firstArticle.hasOwnProperty('body') && firstArticle.body != null) {
                var template = document.createElement('template');
                template.innerHTML = firstArticle.body.trim();
                //console.log(template.content);
    
                const contentElements = template.content;
                divElement.appendChild(contentElements);
                //console.log(contentElements);
                //setArticleContent(contentElements);
            } else {
              setArticleContent([<h1 className='text-center'>Article body is missing or empty.</h1>]);
            }
          } else {
            setArticleContent([<h1 className='text-center'>Sorry, we can't find the article you're looking for.</h1>]);
          }
        } catch (error) {
          console.error('Error fetching article details:', error);
          setArticleContent([<h1 className='text-center'>Sorry, we can't find the article you're looking for.</h1>]);
        }
      };

    useEffect(() => {
        // setIsPageLight(false);
        //console.log(params.slug);
        getArticleDetails(params.slug)
        // switch (params.slug) {
        //     case "how-to-collaborate-productively-in-any-work-set-up":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>How to Collaborate Productively in Any Work Set-up</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article8-img-a.png")} alt=""/>
        //                     <p>Productive team collaboration makes a business work</p>
        //                 </div>
        //                 <p>Collaboration is the key to a productive and continuous business environment. With remote work at
        //                     the forefront of current work setups in most companies, collaboration is the key to a productive and
        //                     continuous business.
        //                     It is a sustainable workflow that can impair the in-office setup to a flexible working environment
        //                     where every worker can thrive for business success.
        //                     </p>
        //                 <p>But how can one collaborate well in a remote environment? This might pose a problem when
        //                     technology was not as advanced as it is now. However, with multiple tools available in the market,
        //                     remote work can now be more collaborative than ever.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article8-img-b.png")} alt=""/>
        //                     <p>Ensure business continuity with useful collaborative tools and practices</p>
        //                 </div>
        //                 <h1>Optimize team workflow</h1>
        //                 <p>One of the keys to productive team collaboration in a flexible environment is an effective workflow.
        //                     This can ensure that team members are doing their jobs on a set timeline for ensured business
        //                     continuity. Work management platforms such as Asana, Trello, Airtable, Evernote, and more are
        //                     advisable for a more organized workflow.</p>
        //                 <h1>Efficient communication apps</h1>
        //                 <p>Make use of messaging apps that can help collaboration easier wherever employees are – whether
        //                     in-office or working remotely from home. TeLavi Cloud has an effective messaging app that is easy to
        //                     use whether it’s an individual chat or a group message chat. Additionally, teams should sit down and &nbsp; 
        //                     <a href="https://www.forbes.com/sites/forbestechcouncil/2022/08/15/addressing-collaboration-and-team-building-in-remote-work-environments/?sh=7378db9420e2">set expectations on realistic response times</a> for a team so it can reduce pressure and ensure a
        //                     healthy work environment.</p>
        //                 <h1>Easier work life with an all-in-one platform</h1>
        //                 <p>With an all-in-one platform, everything is easier and more convenient. You can just use one app for
        //                     messages, office landline phone calls & voicemail messages, team meetings, and more. Unified
        //                     communication platforms such as TeLavi Cloud creates a highly productive collaborative environment
        //                     so businesses can continue to thrive even if employees are working from home or anywhere else.</p>
                    
        //             </>
        //         )
        //         break;
        //     case "laguna-to-launch-centralized-hotline-number-for-easy-access-to-service-and-faster-government-response":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag newspr">News and PR</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Laguna to launch centralized hotline number for easy access to
        //         service and faster government response</h1>
        //                 <p>As part of its initiatives towards digital transformation, the provincial government of Laguna has taken its first step in becoming a Smart Province
        //                     with the launch of a centralized hotline that will ensure easy access to the services for Lagunenses, whether emergency response, official
        //                     business, public service and even for reports and inquiries.</p>
        //                 <p>Laguna Governor Ramil Hernandez said, “Our main goal is to provide an easier channel for people to contact our offices faster so we can
        //                     immediately respond to their needs. As with everything we do in the province, we always have our constituents in mind first with our Serbisyong
        //                     Tama principle. If we are easier to be reached, we can provide services quicker and more efficient.”</p>

        //                 <p>Gov. Hernandez added that centralizing number with their own local numbers will streamline the work process and ensure that any specific
        //                     concern or query from the people will land to the correct office.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article21-img-a.png")} alt=""/>
        //                     <p>(From left) Angela Gutierrez, GLC head of marketing; Shirley Billedo, GLC national sales head; Laguna Governor Ramil Hernandez; MJ Hernandez, GLC project lead; Allan Hechanova, PLDT relationship manager</p>
        //                 </div>
                        
        //                 <p><strong>Dial #LAGUNA</strong></p>
        //                 <p>Providing the technology for this ground-breaking and innovative initiative is Gur Lavi Corp.   (GLC) one of the fastest growing
        //                     telecommunications distributors in the Philippines in partnership with PLDT, with one of its flagship brands – TeLavi Cloud – considered to be a
        //                     pioneering all-in communication platform in the country today.</p>
        //                 <p>GLC President and CEO Erwin Co shares, “In the past, we have done several partnerships with the local government wherein we offered our on
        //                     premise and infrastructure solutions however, our collaboration for the Laguna hotline number is our first implementation in the public sector in
        //                     terms of hosted PBX. With this project, we will help the province gather more partners and opportunities not just in the public sector but also
        //                     from private organizations from SMEs to Large enterprises with remote branch offices.”</p>
        //                 <p>Co also highlighted that GLC has engagement with different government offices already with the same set up that was done for Laguna, from
        //                     barangay as the smallest administrative division to municipality up to the provincial government. The list includes Barangays San Lorenzo and
        //                     Bel-Air in Makati City, Municipalities of Teresa and Morong in the Rizal province and for the whole province of Batangas and Bulacan.</p>
        //                 <p>With #LAGUNA, residents of Laguna only need to know one number to call to be able to reach the agencies and the offices of the provinces.
        //                     They just have to dial <strong>#LAGUNA (#524862).</strong></p>
        //                 <p>Co also explained that having Telavi Cloud has a plethora of advantages for Laguna in their journey to be future-proof. The project will not just
        //                     improve the communication inside their office, but also their collaborations. People can join an audio conference call with a single click, use
        //                     individual and group messaging to quickly and easily communicate with colleagues, and create workspace for teams where they can chat in real
        //                     time and share important information, especially during calamities.</p>
        //                 <p>“The array of tools at their team’s fingertips keep the communication and the exchange of ideas to flow seamlessly. The result? A network of
        //                     employees that are connected, informed and armed with the tools they need to perform their jobs to the best of their ability,” Co said.</p>
        //                 <p>Project Lead MJ Hernandez echoed the same sentiments as the innovative initiative will soon benefit many people, “In GLC, it is always our
        //                     mission to support the community. We understand the challenges that everyone is facing now that’s why we created effective and user-friendly
        //                     solutions that will connect people wherever they are to institutions or organizations the fastest way possible.”
        //                     </p>
        //                 <p>Hernandez emphasized that Telavi Cloud is cost efficient and gives remarkable benefits that ensures business continuity, flexible work
        //                     arrangement for teams whether together or remotely apart, and adaptability and compliance even under stricter quarantine measures being
        //                     implemented.</p>
        //                 <p><strong>Better services for years to come</strong></p>
        //                 <p>Gov. Hernandez vows to continue to deliver better services to his constituents and he believes that he can better understand the need of the
        //                     people if they are accessible and can easily be reached.</p>
        //                 <p>One way of interacting with Lagunenses is being active in his social media page Gov. Ramil Hernandez on Facebook where he uses it as a
        //                     channel to communicate and share relevant information to constituents. He also conducts “People’s Day” every Monday and Thursday where he
        //                     listens to the concerns and issues in the province.</p>
        //                 <p>“We are always looking for better ways to serve our people. Just recently, we launched the Province of Laguna Employment and Information
        //                     System by PESO (PLEIS), an online job portal which is the first in the region. Rest assured that we will continue to innovate and create projects,”
        //                     Gov. Hernandez added.</p>
        //                 <p>The #LAGUNA Telavi Cloud project and the centralization of voice and data system are seen as just the start of a long and innovative partnership
        //                     between the Provincial</p>
        //                 <p>Government of Laguna and GLC. The first phase of the project was mobilized in November 2020 followed by phase 2 which is the integration to
        //                     Telavi Cloud and #LAGUNA that started on May 2021 and is now ready to be launched and implemented.</p>
        //                 <p>The maintenance and warranty for this project will last for a 5-year period where it is forecasted to provide 95.2 km fiber network connection that
        //                     will interconnect CCTV cameras all over Laguna’s National Road, from Barangay Paagahan in Mabitac, Laguna to United Boundary Barangay San
        //                     Antonio, San Pedro City Laguna and integrate other Smart City components like smart street lights, traffic lights, PA System and Telemetry
        //                     devices.</p>
        //             </>
        //         )
        //         break
        //     case "6-advantages-of-cloud-telephony":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>6 Advantages of Cloud Telephony</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article12-img-a.png")} alt=""/>
        //                     <p>Cloud telephony has a lot of benefits for companies and individuals.</p>
        //                 </div>
        //                 <p>Cloud telephony uses VoIP to connect phone calls using the internet. It is an advanced
        //                     telecommunications service that uses the cloud, creating flexibility to do business remotely. With this,
        //                     there are a lot of advantages that companies and even individuals will benefit from.
        //                     </p>
        //                 <p>Here are six advantages of using cloud telephony:</p>
        //                 <ol>
        //                     <li>
        //                         <p><strong>Cost efficiency and affordability.</strong> Unlike traditional communication systems, cloud telephony is
        //                             cost-efficient. Traditional phone systems require a <a href="https://www.kloudtalk.com/blog/cloud-telephone-system-traditional-phone/">heavy amount of investment</a>, adding to the fact
        //                             that their maintenance costs are also quite hefty. On the other hand, cloud-based systems are
        //                             hosted by third-party providers which keep initial and maintenance costs at a minimal amount. Some
        //                             cloud telephony providers such as <a href="https://telavi.com.ph/">TeLavi Cloud</a> even provide affordable subscription plan options as
        //                             needed.
        //                             </p>
        //                     </li>
        //                     <li>
        //                         <p><strong>High mobility & flexibility.</strong> Cloud telephony provides mobility and flexibility to workers that are on
        //                             the go in their professional occupations. It’s perfect for hybrid and remote employees that need to
        //                             receive and make calls wherever and whenever they need to. There’s no need to go to the office just
        //                             because of an important call.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Always updated, never outdated.</strong> With cloud telephony, services will always be <a href="https://insightssuccess.com/the-top-5-advantages-of-cloud-telephony/">updated and never
        //                             outdated.</a> Every communication will be readily available so inter-office and outside-office calls are
        //                             now modernized – whether it comes to security or communication systems themselves.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Reduced carbon footprint.</strong> This advantage is mostly overlooked, but having a reduced footprint is
        //                             important, especially in our current environmental climate. Do your part to go green by choosing to
        //                             move to cloud services, which do not require <a href="https://justcall.io/blog/top-10-business-benefits-of-cloud-telephony.html">energy-draining infrastructures</a>.
        //                         </p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Easy accessibility.</strong> Easier and more precise control and access to devices and data can be
        //                             achieved with cloud services, especially cloud telephony. Valuable data and contacts that are hosted
        //                             in the cloud can help communication become easily accessible.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Less downtime.</strong> Unlike traditional phone systems, cloud telephony requires less downtime.
        //                             Because it is regularly updated and modernized, cloud telephony does not break down quite easily. In
        //                             times of disasters, traditional phones may also go down, but <a href="https://telavidev.gurlavi.com.ph/media/6-advantages-of-cloud-telephony/">cloud telephony solutions include cloud
        //                                 storag</a>e where all information is stored and secured which can be accessed anytime and anywhere.</p>
        //                     </li>
        //                 </ol>
        //             </>
        //         )
        //         break;
        //     case "top-6-best-features-in-a-unified-communications-platform":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Top 6 Best Features in a Unified Communications Platform</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article13-img-a.png")} alt=""/>
        //                     <p>Look out for the best features in a Unified Communications (UC)Service or Platform</p>
        //                 </div>
        //                 <p>In a fast-paced society, there is always an increasing demand for software or applications for better
        //                     productivity. As remote and hybrid work continues, it’s important to use platforms that are all in one
        //                     unified place.
        //                     </p>
        //                 <p>Unified communications (UC) platforms or <a href="https://www.gartner.com/en/information-technology/glossary/unified-communications-uc">products</a> (e.g. equipment, software, and services) provide
        //                     and integrate communications and collaboration tools such as IP phones, web and video
        //                     conferencing, voicemails, personal & team messaging, and more. But what should be taken into
        //                     account when looking for a UC service or platform?
        //                     </p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article13-img-b.png")} alt=""/>
        //                     <p>Effective features in a UC Platform boosts productivity</p>
        //                 </div>
        //                 <p>Here are the <strong>6 best features</strong>  to look for in a Unified Communications <a href="https://www.colt.net/resources/what-is-unified-communications/">service</a>  or solution:</p>
        //                 <ol>
        //                     <li>
        //                         <p><strong>High-quality audio and video.</strong> The quality of audio and video is vital when it comes to meetings and
        //                             general communication. It is a critical component when choosing a platform for effortless and
        //                             effective connectivity.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Easy to use.</strong> Employees and users alike prefer technology that is uncomplicated and <a href="https://www.techtarget.com/searchunifiedcommunications/feature/9-most-useful-unified-communications-features-in-business">easy to use</a>. A
        //                             UC service or solution should not give a hard time to its users, especially when joining online
        //                             meetings or connecting to a voice call.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Team & personal messaging capabilities.</strong> Message or chat feature in a UC platform is one of the
        //                             basic features that’s important to have. It is also vital for encouraging online social communication
        //                             and interaction.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Screen sharing feature</strong> To encourage an easier team collaboration, a screen sharing feature in a UC
        //                             platform is especially useful. This feature is especially needed in brainstorming ideas, presenting
        //                             decks, and more.
        //                             </p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Integration with applications.</strong> Choose a UC solution that should <a href="https://www.colt.net/resources/what-is-unified-communications/">“effectively integrate communication
        //                             applications into a single front-end interface”</a>. This provides employees access to their necessary
        //                             apps all in one place so they can use it anytime and anywhere, boosting easier remote productivity.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Accessible support.</strong> For the UC platform to work effectively with a company, it needs to have an
        //                             accessible support from the provider. They need to readily guide the company or organization in
        //                             migration to be able to implement the UC solution. Communication between the organization and the
        //                             provider should be important, especially when it comes to technical issues, and more.</p>
        //                         <p>In choosing the best unified communications platform, it is necessary to select one that would best
        //                             utilize all the features that the provider offers. It will not only boost the employee’s productivity, but it
        //                             will boost the company’s business continuity and competitiveness within their respective industries.</p>
        //                         <p>Learn more about Unified Communications and Call Center Telephony Solutions Platform with <a href="https://telavi.com.ph/products-services/#unified">Telavi
        //                             Cloud.</a></p>
        //                     </li>
        //                 </ol>
        //             </>
        //         )
        //         break;
        //     case "city-of-san-fernando-takes-technological-leap-with-a-unified-hotline-number":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag newspr">News and PR</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>City of San Fernando takes technological leap with a
        //                     Unified Hotline Number</h1>
        //                     <div className="image-container">
        //                         <img src={require("../assets/images/article-img/article20-img-a.png")} alt=""/>
        //                         <p>(Caption: Mr. Leandro Santos VP for Operations GLC, Atty. James
        //                             Francis Villanueva City Information and Communications
        //                             Technology Officer, Ms. Shirley Billedo GLC Head of Sales, City Mayor
        //                             Vilma Caluag San Fernando Pampanga, Mr. Richard Echon GLC Head of Product and MJ Hernandez GLC Sales Manager)</p>
        //                     </div>
        //                 <p>The city government of San Fernando in the province of
        //                     Pampanga has introduced a Voice Connectivity Integration
        //                     or One Hotline Number – an effective means of
        //                     communication for residents of the city.</p>
        //                 <p>Gur Lavi Corp. (GLC), a prominent provider of voice
        //                     communication solutions in the nation, supplied the
        //                     technology for this groundbreaking project in San Fernando
        //                     City, utilizing their flagship product, TeLavi, an innovative all-in-one communication platform recognized as a trailblazer in the country.</p>
        //                 <p>GLC President and CEO Erwin Co said, “Telavi offers a great
        //                     help for San Fernando City especially in improving the internal
        //                     and external communication of the local government. This will
        //                     be useful for the people of San Fernando asitspeed up the LGU’s
        //                     response to their needs especially during emergencies and
        //                     calamities.”</p>
        //                 <p>San Fernando City Mayor Vilma Caluag shared that they are
        //                     already promoting the centralization of the city’s voice
        //                     connectivity for the improvement of their public service.</p>
        //                 <p>Residents only need to remember one number to reach local
        //                     government agencies. They just have to dial the Kayabe
        //                     Hotline Number 961HELP (4357).</p>
        //                 <p><i>“This initiative will help not only in managing our processes but
        //                     also in immediately responding to the needs of our dear people in
        //                     San Fernando City by improving and accelerating
        //                     communication in the local government,”</i> added Mayor Caluag.</p>
        //                 <p>She also emphasized that it will be easier for their LGU to
        //                     reach out to their 35 barangays because the technology
        //                     they are implementing can give quick updates especially in
        //                     the middle of disaster response during typhoons, intense
        //                     flooding and earthquakes.</p>
        //                 <p>On top of all of these benefits, Co envisions other
        //                     opportunities in this partnership because the project can
        //                     pave the way for more support not only in the public sector
        //                     but from private organizations as well from SMEs to Large
        //                     corporation with branches or satellite offices in remote
        //                     areas. A better communication platform for the LGU is also
        //                     in place with the technology where government employees
        //                     can stay connected with their office colleagues or from other departments using the TeLavi app.</p>
        //                 <p>The San Fernando City project Kayabe Hotline Number
        //                     961HELP (4357) through Telavi is seen as a part of the
        //                     ground-breaking initiatives in the province of Pampanga in
        //                     its journey to be future-proof. Investing in the advancement
        //                     of technology to facilitate and fast track response to the
        //                     needs of the people is a proof of the local government’s
        //                     show of concern for their welfare.</p>
        //                 <p>To know more about TeLavi and other products services,
        //                     visit www.telavi.com.ph.</p>
        //             </>
        //         )
        //         break;
        //     case "10-reasons-to-shift-to-cloud-office":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>10 Reasons to Shift to Cloud Office</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article6-img-a.png")} alt=""/>
        //                     <p>Enjoy the benefits when you shift to the Cloud Office</p>
        //                 </div>
        //                 <p>There are a lot of misconceptions about shifting from legacy or on-premise to cloud communication
        //                     systems. As a result, companies are left to settle with outdated solutions and cannot fully support
        //                     business needs.
        //                     </p>
        //                 <p>But shifting to the Cloud office is necessary for business continuity, and there are many reasons to
        //                     make that change. Here are some important reasons why companies should make the change and
        //                     shift to Cloud office.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article6-img-b.png")} alt=""/>
        //                     <p>Cloud Office could be the best solution for your business</p>
        //                 </div><br /><br />
        //                 <h1>10 Reasons to Shift to Cloud Office</h1>
        //                 <ol>
        //                     <li>
        //                         <p><strong>Flexibility to work anywhere</strong> - Employees can work from anywhere and create a cloud office with
        //                             cloud-connected services.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Supports business continuity</strong> - Because work can be done remotely, in-office, or anywhere using
        //                             the cloud, every work day is business as usual. There are no missed opportunities with the cloud
        //                             office.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Unlimited or larger storage</strong> - A lot of cloud office platforms <a href="https://www.proventeq.com/blog/cloud-office-and-its-business-benefits#:~:text=Cloud%20office%20suites%20can%20help,organisation%20to%20improve%20productivity%20levels.">provide large storage space</a> per user,
        //                             giving the capacity to files for easier collaboration.
        //                             </p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Easier security management</strong> - Traditional practitioners think that cloud systems are more
        //                             susceptible to breaches in security. But this largely <a href="https://www.kaspersky.com/resource-center/definitions/what-is-cloud-security">depends on the cloud provider</a> whose basic
        //                             understanding of data protection is securing privacy. It’s easier to secure files in the cloud especially
        //                             if providers and users have a good understanding of what to secure.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Better app support</strong> - Since on-the-go apps are necessary for productivity, <a href="https://www.proventeq.com/blog/cloud-office-and-its-business-benefits#:~:text=Cloud%20office%20suites%20can%20help,organisation%20to%20improve%20productivity%20levels.">modern Cloud office apps are now optimized</a> to deliver a hassle-free user experience.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Increased productivity and efficiency</strong> - Shared files and easier communication provide an increase
        //                             in business productivity.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Easier access to shared files</strong> - Team members can collaborate easily with shared files and emails.
        //                             They can access shared cloud information virtually anywhere, creating a productive cloud office.
        //                             </p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Convenient communication with team members</strong> - Communication is now easier with the existence
        //                             of cloud messaging so everyone is always connected during business hours.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Instant access to the latest updates and features</strong> - With a cloud office, apps are always updated for
        //                             a hassle-free experience. Unified communication apps such as <a href="https://telavi.com.ph/">TeLavi Cloud</a> provides a subscription based option depending on the company allowance costs.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Reduced operational costs</strong> - Shifting from legacy or on-premise to the cloud can be beneficial in the
        //                             long run especially when it comes to operational costs. If companies choose to take the risk of
        //                             <a href="https://www.google.com/url?q=https://www.dsm.net/it-solutions-blog/how-the-cloud-is-shifting-it-to-operating-expense&sa=D&source=apps-viewer-frontend&ust=1697112116289226&usg=AOvVaw1pQv0myf0L9P46_7lS4yCe&hl=en">becoming a cloud office and make it an operational expense</a>, there could be more savings that can be
        //                             allotted to the business’ product development and more.</p>
        //                     </li>
        //                 </ol>
        //             </>
        //         )
        //         break;
        //     case "4-useful-tips-to-make-a-hybrid-setup-work":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>4 Useful Tips to Make a Hybrid Setup Work</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article1-img-a.jpg")} alt=""/>
        //                     <p>Make the hybrid work setup actually work</p>
        //                 </div>
        //                 <p>Hybrid work has created an environment where employees can work both onsite and remotely. With
        //                     the premise of providing a good work-life balance and better savings, <a href="https://www.bworldonline.com/labor-and-management/2022/05/20/449560/cisco-study-finds-92-in-philippines-prefer-hybrid-work-arrangements/">a good majority of Filipino employees prefer hybrid work.</a> companies still have reservations about this work setup, with the same survey indicating that only
        //                     <a href="https://www.bworldonline.com/labor-and-management/2022/05/20/449560/cisco-study-finds-92-in-philippines-prefer-hybrid-work-arrangements/">a small percentage of companies</a> are prepared for hybrid work.
        //                     </p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article1-img-b.jpg")} alt=""/>
        //                     <p>Hybrid work can work effectively with these useful tips</p>
        //                 </div><br /><br />
        //                 <p>To prepare a proper working environment for both the management and employees, there are four
        //                     useful tips to follow to make a hybrid setup work:</p>
        //                 <ol>
        //                     <li>
        //                         <p><strong>Create a Hybrid Model</strong>. A <a href="">hybrid model</a> is an approach to workforce management that drives
        //                             increased productivity and job satisfaction. You can choose from <a href="">four types of hybrid models</a>: the
        //                             flexible hybrid model, fixed hybrid model, office-first hybrid model, or remote-first hybrid model. No
        //                             matter which model you choose, you must do proper research and communication between the
        //                             management and the employees to see which can best fit the company or organization for better
        //                             productivity.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Importance of portable equipment.</strong>. Because a hybrid work setup usually requires in-office and
        //                             remote work, employees must have equipment that can be portable. They should be equipped with
        //                             the proper tools and apps such as Telavi Cloud which is an excellent unified communications
        //                             platform where you can take and direct calls anytime and anywhere.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Make In-Office Days the new “Working Offsite”.</strong>. Instead of thinking that working at the office is the
        //                             norm, try the opposite and think of remote work as the norm. On days that employees are due to
        //                             work onsite, make the most out of it by <a href="https://www.forbes.com/sites/andrewfilev/2022/05/19/3-ways-to-make-the-hybrid-work-model-work-in-everyones-favor/?sh=576f8b2a3aed">doing more collaboration activities and meetings</a>, recognition
        //                             awards, and more. Utilize the onsite days with catch-up activities between employees and managers,
        //                             so it will not look like employees are not making the most of their time in the office doing the same
        //                             thing they can do while working remotely.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Ensure employees have a working hybrid habit.</strong>. The responsibility in creating something successful
        //                             should just not be on one sole thing alone. For a hybrid or remote work setup to be successful, it has
        //                             to go both ways – the company and employees should work together. With the company, they need
        //                             to create a working model, and with employees, they need to create <a href="https://www.forbes.com/sites/bryanrobinson/2021/06/01/6-habits-of-highly-successful-remote-workers/?sh=41c0a90bcfdb">good working habits.</a> It needs to
        //                             be consistent and should boost productivity, rather than the opposite. Some good habits include
        //                             taking short breaks, making themselves accessible through online platforms, achieving, work-life
        //                             balance, and more.</p>
        //                     </li>
        //                 </ol>
                        
        //                 <p>Remember that a hybrid work setup will only work if there is accountability between both parties and
        //                     that business continuity remains. With this, the company will continue with business as usual, and
        //                     employees will be able to enjoy a good work-life balance.</p>
        //             </>
        //         )
        //         break;
        //     case "5-common-myths-about-hybrid-work":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag newspr">News and PR</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>5 Common Myths about Hybrid Work</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article2-img-a.jpg")} alt=""/>
        //                     <p>Flexible work has been around for years. It’s time to utilize it.</p>
        //                 </div>
        //                 <p>Contrary to popular belief, hybrid work is not a new phenomenon just borne from the pandemic. In
        //                     fact, remote and flexible working arrangements have been around for some time. It existed even
        //                     before the pandemic — just on a <a href="https://digiday.com/marketing/myth-buster-hybrid-working-models-biggest-misconceptions/">smaller scale</a>. There are a lot of other <a href="https://www.linkedin.com/business/talent/blog/talent-strategy/4-myths-about-hybrid-work-that-hold-companies-back"></a>myths about hybrid work
        //                     within the company, which most, if not all, are just misconceptions.</p>
        //                 <p>Based on a <a href="https://ils.dole.gov.ph/social-dialogue/2018-sd/a-descriptive-study-on-off-site-work-arrangement-in-the-philippines"></a>local study, most employees prefer a flexible work schedule. <strong>76% of respondents</strong> said
        //                     that the cut in transport expenses, among others, plays a major role in their flexible preference.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article2-img-b.jpg")} alt=""/>
        //                     <p>Are employees more stressed while working remotely?</p>
        //                 </div>
        //                 <h1>1st Hybrid Work Myth: Employee well-being doesn’t improve on flexible work</h1>
        //                 <p>The pandemic has forced people to adapt to new routines that were difficult to adopt at first.
        //                     However, this does not mean that employees’ well-being suffered the whole time. In fact, <a href="https://www.gmanetwork.com/news/money/companies/832202/majority-of-filipino-workers-happier-with-hybrid-work-options-study/story/">68.1% of
        //                         Filipino employees</a> said that their well-being has improved in a hybrid work setup. A majority also
        //                     mentioned that they eat healthier and even exercise more.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article2-img-c.jpg")} alt=""/>
        //                     <p>Are employees more productive working onsite?</p>
        //                 </div>
        //                 <h1>2nd Hybrid Work Myth: Productivity declines when doing hybrid work</h1>
        //                 <p>Although there was a period of transition, productivity did not, in actuality, plummet. In a recent Cisco
        //                     study, they found that <a href="https://malaya.com.ph/news_business/wfh-improved-well-being-productivity-of-workers-survey/">79% of employees felt that they were productive</a> in a hybrid work setup. An
        //                     expert suggests that much of the productivity gains in this work setup are mostly because of the
        //                     <a href="https://www.forbes.com/sites/davidrock/2021/04/22/how-to-not-mess-up-return-to-the-office/?sh=9364f7b2bf7e">sense of autonomy given to the employees</a>. This is because options are available regarding their
        //                     preferred work arrangement.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article2-img-d.jpg")} alt=""/>
        //                     <p>Is hybrid work really flexible?</p>
        //                 </div>
        //                 <h1>3rd Hybrid Work Myth: Flexible work is… not flexible</h1>
        //                 <p>There is a massive disconnect between a company’s understanding of work flexibility. <a href="https://digiday.com/marketing/myth-buster-hybrid-working-models-biggest-misconceptions/">One expert</a> in
        //                     remote operations said that most companies have a poor understanding of hybrid work setup. They
        //                     understand hybrid as wanting employees in the office for a set amount of non-flexible time (e.g.,
        //                     Mondays, Wednesdays, and Fridays on a 9-5 basis). Whereas, employees typically understand hybrid
        //                     work as wanting to have the flexibility and option to work remotely and go to the office on days that
        //                     they can. This disconnect often creates misunderstandings between employees and companies. This
        //                     can often be solved with proper dialogues and a <a href="https://telavi.com.ph/8-essential-things-to-consider-when-transitioning-to-a-hybrid-work-setup/">clear company policy</a>.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article2-img-e.jpg")} alt=""/>
        //                     <p>Does the company stop working when employees don’t work onsite?</p>
        //                 </div>
        //                 <h1>4th Hybrid Work Myth: Everything stops when people aren’t in the office</h1>
        //                 <p>n just the past few years, we have seen how technology has evolved. It bridged the gap between
        //                     people, making it easier to communicate, thus becoming more productive. With continuous
        //                     innovation, communication has become easier. In particular, unified communication tools like <a href="https://telavi.com.ph/?utm_source=site&utm_campaign=5-common-myths-about-hybrid-work">TeLavi</a>
        //                     creates better communication anytime, anywhere. You can work anytime, anywhere with the available
        //                     technology nowadays.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article2-img-f.jpg")} alt=""/>
        //                     <p>Can you save more with the hybrid work setup?</p>
        //                 </div>
        //                 <h1>5th Hybrid Work Myth: Hybrid work setup is more expensive</h1>
        //                 <p>As in any type of transition process, some decisions can make you spend more or less. According to a
        //                     <a href="https://www.philstar.com/business/2022/05/23/2183126/filipino-workers-save-p340k-year-under-hybrid-work-report">recent report</a>, however, a hybrid work setup lets a majority of Filipino employees save up to P300,000
        //                     a year. Under the hybrid arrangement, it lets employees save on fuel, commuting costs, and other
        //                     entertainment costs.</p>
        //                 <p>But it’s not just the employees who get to enjoy savings – companies, too, are reaping benefits from a
        //                     hybrid work setup. Organizations that let their employees work remotely half of the week enjoy a
        //                     <a href="https://www.businesstimes.com.sg/companies-markets/hybrid-working-can-save-employers-us11000-per-worker-a-year-iwg">hefty amount of workspace</a> and electricity costs. And since hybrid work lets employees have higher
        //                     morale, companies get to enjoyreduced absenteeism and turnover as well.</p>
        //                 <h1>Breaking the prejudice</h1>
        //                 <p>There are a lot of misconceptions when it comes to progressive and newer takes on professional
        //                     setups. Hiccups and prejudices can occur, especially if something is new and different from the
        //                     traditional eye. However, open discussions, clear policies, and mutual trust can help stir the direction
        //                     to the right path. All for a brighter and modernized future.</p>
        //             </>
        //         )
        //         break;
        //     case "5-effective-ways-to-thrive-in-a-hybrid-work-environment":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>5 Effective Ways to Thrive in a Hybrid Work Environment</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article3-img-a.jpg")} alt=""/>
        //                     <p>Thrive in your work even if you’re working remotely from home</p>
        //                 </div>
        //                 <p>One of the basic things that should be in place in a successful flexible work setup is a hybrid work
        //                 model. With a working framework and rulebook in place, it’s now time to find effective ways for
        //                 employees to thrive and remain productive while working remotely or in-office, especially as <a href="https://www.bworldonline.com/corporate/2022/09/23/476332/more-firms-to-make-hybrid-work-available-for-all-by-2025-jll/">more
        //                     companies in the Asia-Pacific region</a> are likely to have remote and flexible arrangements in the next
        //                 few years.</p>
        //                 <p>Employees and companies in the country who enjoy the hybrid work setup can also rejoice as even
        //                     <a href="https://www.bworldonline.com/labor-and-management/2022/05/20/449560/cisco-study-finds-92-in-philippines-prefer-hybrid-work-arrangements/">local politicians</a> are now starting to advocate for this work flexibility. However, only <a href="https://www.bworldonline.com/labor-and-management/2022/05/20/449560/cisco-study-finds-92-in-philippines-prefer-hybrid-work-arrangements/">29% of local
        //                         companies or organizations</a> personally believe that they are prepared for a flexible setup. Despite
        //                     this, however, employees should always be prepared to adapt to any setup to sustain or increase
        //                     productivity and promote business continuity.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article3-img-b.jpg")} alt=""/>
        //                     <p>Work effectively whether you’re at the office or at home</p>
        //                 </div><br /><br />
        //                 <h1>Here are <a href="https://www.flexjobs.com/blog/post/thrive-hybrid-work-environment/">5 effective ways</a> to thrive in a hybrid work environment:</h1>
        //                 <p><strong>Be prepared for technical challenges or issues.</strong> There might be cases in which employees will not be
        //                     able to reach the company IT - in cases such as these, employees must be able to be equipped to
        //                     solve minor technical issues. Remember to always have a backup battery, and portable wifi, or scout
        //                     the nearest coffee shop in case electricity goes out.</p>
        //                 <p><strong>Keep the communication lines open.</strong> Employees must always be reachable during work hours. It’s
        //                     important to keep communication lines open when it comes to working remotely as it also builds trust
        //                     among the team members.</p>
        //                 <p><strong>Create a “work space” and “personal space” at home.</strong> With a flexible workspace, a work-life balance
        //                     is important. It is always a good reminder put the division of workspace from personal space to avoid
        //                     burnout. This way, employees can remain productive while not thinking about work 24/7.</p>
        //                 <p><strong>Include breaks in your work routine while working from home.</strong> In connection to work-life balance,
        //                     it’s also important to take regular breaks, as if working at the office still. This way, employees can
        //                     have a break to think anew, leading to better productivity.</p>
        //                 <p><strong>Use the Cloud.</strong> The Cloud is not only convenient but it’s a smart way to do work productively. Putting
        //                     everything in the Cloud creates a dynamic environment where files are securely accessible to
        //                     everyone in the office.</p>
        //                 <p>For a more optimized experience, use a unified communications platform like <a href="https://telavi.com.ph/">TeLavi Cloud.</a> It is one
        //                     of the best ways to keep the communication lines open, at the same time, it makes team
        //                     collaboration easier and better.</p>
        //             </>
        //         )
        //         break;
        //     case "5-essential-tools-to-use-for-hybrid-work":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>5 Essential Tools to use for Hybrid Work</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article4-img-a.jpg")} alt=""/>
        //                     <p>Must-have tools for a productive hybrid work</p>
        //                 </div>
        //                 <p>A lot of companies have begun to shift to a flexible work setup. To ensure success for business
        //                     continuity, companies and employees must have specific tools to function well. But why do hybrid
        //                     workplaces need such tools? Workable tools that professionals can use both in-office and remotely
        //                     provides easier access to files and most importantly internal and external communication.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article4-img-b.jpg")} alt=""/>
        //                     <p>There should be the presence of essential tools for a successful hybrid work environment</p>
        //                 </div><br /><br />
        //                 <p>Here are <a href="https://www.edenworkplace.com/blog/6-essential-tools-to-create-a-great-hybrid-workplace">5 essential tools</a> to use for a successful hybrid work setup:</p>
        //                 <ol>
        //                     <li>
        //                         <p><strong>Proper equipment</strong> – Employees should be properly equipped with materials that they can take with
        //                             them anytime. Employees who are in no need of heavy specs should be equipped with a sturdy
        //                             laptop that will fit best with their respective professions and tasks.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Messaging app</strong> – one of the basic necessities in a flexible office is communication between team
        //                             members. Selecting a messaging app that works well for every member should be a priority,
        //                             especially when it comes to urgent requests.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Project management tools</strong> – to keep track of everybody’s progress in an organized way, it’s highly
        //                             recommended to get a project management tool. Apps such as <a href="https://trello.com/">Trello</a> and <a href="https://asana.com/">Asana</a> are helpful,
        //                             especially when it comes to projects that require constant updates from team members.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>HR software</strong> – for attendance management, companies should consider getting HR software that
        //                             could work well with them. Since some remote or hybrid employees do not physically report to the
        //                             office, they can take their attendance through HR software where they can log in and out properly.
        //                             One of the most popular ones in the country is <a href="https://sprout.ph/">Sprout.</a></p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Virtual meeting app</strong> – Video conferencing needs soared during the height of the pandemic. It’s
        //                             natural that virtual meeting apps are one of the must-have tools that flexible workers should have in
        //                             their equipment. <a href="https://telavi.com.ph/">TeLavi Cloud</a> app has a virtual meeting feature as well as messaging and voice call
        //                             features – all in one platform. With TeLavi, communication is made easier anytime and anywhere.</p>
        //                     </li>
        //                 </ol>
        //                 <p>With the flexibility of workers, it’s important that they should create an environment where they can
        //                     thrive. Through this, they are able to be more productive in the workplace no matter where it is. They
        //                     should have the proper equipment, and at the same time, they should have the tools to be able to do
        //                     the task they need to do on a daily basis – whether they are at the office or working from home.</p>
        //             </>
        //         )
        //         break;
        //     case "8-essential-things-to-consider-when-transitioning-to-a-hybrid-work-setup":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>8 Essential Things to Consider when Transitioning to a Hybrid Work Setup</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article5-img-a.png")} alt=""/>
        //                     <p>Make your transition to a hybrid work setup easier.</p>
        //                 </div>
        //                 <p>A hybrid setup is one of the options companies are now choosing to do instead of going full-remote or full-onsite work.
        //                     There could be challenges to transitioning to a hybrid work setup, especially since several studies have shown that those who <a href="https://www.apollotechnical.com/working-from-home-productivity-statistics/">work remotely are
        //                         47% more productive than those working onsite.</a></p>
        //                 <p>However, to adapt to the new normal lifestyle, hybrid is still the best option to go, especially if you want to promote a better work-life balance.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article5-img-b.png")} alt=""/>
        //                     <p>Hybrid work promotes a better work-life balance.</p>
        //                 </div><br /><br />
        //                 <p>If your company is adopting a full hybrid work setup, there are some things you should consider before you transition.</p>
        //                 <p>Here are 8 essential things to consider when transitioning your company to hybrid work:</p>
        //                 <ol>
        //                     <li>
        //                         <p><strong>Learn who can work remotely.</strong> Not everybody can work onsite, some people may have a hard time commuting to work or are not healthy enough
        //                             to go to the office. Talk to the employees and see how their current situation can adapt to the hybrid work setup.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Invest in equipment or tools that can be used anywhere.</strong> Since a hybrid work setup requires working both at home and outside, it’s better to
        //                             provide employees with the proper tools and equipment to make working easier no matter what the setup is. We recommend using unified cloud based communications tools such as the <a href="https://telavi.com.ph/?utm_source=site&utm_campaign=8+Essential+Things+to+do+When+Transitioning+to+Hybrid+Work+Setup">Telavi app</a> to make mobility easier. With this telephony app, it feels like you have your office anywhere
        //                             you go.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Create a clear policy with hybrid work.</strong>  To make the transition easier for the employees, <a href="https://flexisourceit.com.au/resources/blog/how-to-prepare-your-team-for-a-hybrid-work-environment/">a clear policy should take place</a>. When they are working
        //                             remotely, there should always be rules to follow and ensure proper dissemination of information about the type of hybrid work model that the
        //                             majority of employees will be in favor of.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Stay connected.</strong> When working remotely, ensure that employees are responsive to queries using one messaging tool. Likewise, companies
        //                             should also ensure that they are available for any professional queries.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Empower employees and managers.</strong>  Enable the skillset not only of the employees but also the managers. They need to be <a href="https://flexisourceit.com.au/resources/blog/how-to-prepare-your-team-for-a-hybrid-work-environment/">properly trained to
        //                             adapt to the new work setup</a> and to learn how to create boundaries between work and personal life. At the same time, utilize the remote work
        //                             setup by helping them improve their skills: provide online courses, webinars, and other opportunities to help them grow.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Prioritize health and safety.</strong>  Always keep in mind that your employee’s health and safety should always come first. Although the pandemic has
        //                             slowly calmed down, it is still very much around. Ensure that the office is safe by providing health and safety protocols.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Be more outcome-based.</strong>  When working remotely, it’s important to understand that <a href="https://www.forbes.com/sites/carolinecastrillon/2022/03/06/5-tips-to-thrive-in-a-hybrid-work-environment/?sh=3fe887c61d98">work is not measured merely in hours</a>. Instead, focus on the
        //                             output that the employee is giving. Promote transparency and how much work gets done.</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Provide a suitable working environment.</strong>  Whether it’s at home or at the office, everybody should have a proper workspace. If an employee is
        //                             working from home, they should always be clear of distractions to optimize productivity. But if they are working from the office, they should be
        //                             able to steer clear of unnecessary distractions so they can focus on their work.
        //                             It’s important to keep a good and proper work-life balance when it comes to any work. This keeps a healthy relationship between the employee
        //                             and the company which can help improve productivity that can benefit the company and its employees.</p>
        //                     </li>
        //                 </ol>
        //             </>
        //         )
        //         break;
        //     case "benefits-of-a-hybrid-work-setup":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Benefits of a Hybrid Work Set-Up</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article7-img-a.png")} alt=""/>
        //                     <p>The benefits of hybrid work for employers and employees.</p>
        //                 </div>
        //                 <p>The future of the workforce is hybrid. Multiple studies have shown that <a href="https://www.bworldonline.com/labor-and-management/2022/05/20/449560/cisco-study-finds-92-in-philippines-prefer-hybrid-work-arrangements/">local employees prefer a flexible work arrangement</a>, and they are, in fact, <a href="https://www.gmanetwork.com/news/money/companies/832202/majority-of-filipino-workers-happier-with-hybrid-work-options-study/story/">happier</a> with this type of setup. However, a lot of
        //                     companies and leaders are still on the fence about transitioning from full onsite and full remote to a
        //                     hybrid work setup.</p>
        //                 <p>This arrangement has been a hot topic of discussion ever since the pandemic slowed down. It has
        //                     been much-talked-about that even local senators have called upon different industries to <a href="https://mb.com.ph/2022/08/16/poe-give-hybrid-work-arrangement-a-chance/">“give hybrid
        //                         work a chance.”</a> To those who are still in doubt about a new but not necessarily new work
        //                     arrangement, we are listing down some useful benefits of a hybrid work setup.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article7-img-b.png")} alt=""/>
        //                     <p>A flexible work setup minimizes “The Great Resignation”</p>
        //                 </div><br /><br />
        //                 <h1>Hybrid work reduces attrition.</h1>
        //                 <p>According to a <a href="https://sprout.ph/state-of-hr-2022/">recent study</a>, the Philippines posted a whopping 176% in voluntary resignation across
        //                     all industries. Employees now look for better opportunities such as better compensation, benefits,
        //                     career growth opportunities, and a more flexible work arrangement. A lot of them also <a href="https://www.pna.gov.ph/articles/1168037">resign their jobs</a> because of the lack of work-life balance, uncertainty over the pandemic, and abrupt
        //                     changes in the working scheme – changing from remote work to suddenly reporting to the office
        //                     everyday.</p>
        //                 <p>Hybrid work provides flexibility both for the leaders and employees, at the same time providing a
        //                     work-life-balance that employees prefer. And through this, hybrid work can keep employees and
        //                     <a href="https://www.unleash.ai/future-of-work/hybrid-work-reduces-attrition-by-35/">prevent further attrition</a> while ensuring business continuity.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article7-img-c.png")} alt=""/>
        //                     <p>A balanced time for work and leisure promotes a healthy lifestyle</p>
        //                 </div><br /><br />
        //                 <h1>Hybrid work promotes work-life balance.</h1>
        //                 <p>A lot of employees find a greater <a href="https://economictimes.indiatimes.com/magazines/panache/hybrid-work-is-here-to-stay-the-shift-helps-young-professionals-focus-on-priorities-work-life-balance/articleshow/93271721.cms?from=mdr">work-life balance</a> with a flexible work arrangement. Hybrid work in
        //                     particular offers the flexibility of being able to work from home and report to the office on certain
        //                     days. This takes out the stress of commuting to work every single day, evidently wasting time that
        //                     could be used for more important things. In addition, the “free time” that employees get from working
        //                     remotely can be used to advance their skills which could help benefit the company in the long run.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article7-img-d.png")} alt=""/>
        //                     <p>Enjoy the benefits of more savings</p>
        //                 </div><br /><br />
        //                 <h1>Hybrid work saves employees and employers money.</h1>
        //                 <p>It’s no surprise that employees save a lot when a hybrid work model is implemented. They save on
        //                     transportation costs, food costs, and a lot more. But did you know that a hybrid work model can also
        //                     save employers on costs? With a limited amount of employees at the office, the <a href="https://www.zoho.com/workdrive/digest/top-5-benefits-and-challenges-of-hybrid-work.html">company can save</a> on operating, maintenance, and realty costs. According to <a href="https://sprout.ph/wp-content/uploads/2021/12/White-Paper-Future-of-Work-1.pdf">a study</a>, some work leaders have also
        //                     acknowledged this remote and hybrid work benefit.
        //                 </p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article7-img-e.png")} alt=""/>
        //                     <p>Flexible work conditions bring a different working perspective</p>
        //                 </div><br /><br />
        //                 <h1>Hybrid work increases productivity</h1>
        //                 <p>A hybrid work setup can provide a <a href="https://www.zoho.com/workdrive/digest/top-5-benefits-and-challenges-of-hybrid-work.html">refreshing change</a> of working environment, which can improve an
        //                     employee’s focus and productivity. Since a company offers flexibility, they are more likely to balance
        //                     their workload and accountability when it comes to their output.
        //                 </p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article7-img-f.png")} alt=""/>
        //                     <p>Better team collaboration and communication for flexible work setups</p>
        //                 </div><br /><br />
        //                 <h1>Hybrid work improves collaboration and team relationships</h1>
        //                 <p>Since a hybrid setup provides both face-to-face and remote interactions and collaborations, this
        //                     <a href="https://billieapp.io/2021/10/12/the-benefits-of-a-hybrid-work-model-and-why-its-here-to-stay/">improves collaboration between team members</a>. It allows for better communication and relationships
        //                     which could lead to much more productive output. Collaboration tools in unified platforms such as
        //                     <a href="https://telavi.com.ph/">Telavi Cloud</a> make communication easier whether the employees are at the office or outside, ensuring
        //                     productivity anytime and anywhere.</p>
        //             </>
        //         )
        //         break;
        //     case "hybrid-future-and-fate-of-the-cloud-office":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Hybrid Future & Fate of the Cloud Office</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article9-img-a.png")} alt=""/>
        //                     <p>The future is in the Cloud</p>
        //                 </div>
        //                 <p>Hybrid is the present and the future of a workplace. With <a href="https://www.philstar.com/the-freeman/cebu-business/2022/05/17/2181627/itbpo-sector-hopes-new-admin-supports-hybrid-work-arrangement">labor groups</a>, the <a href="https://www.philstar.com/the-freeman/cebu-business/2022/05/17/2181627/itbpo-sector-hopes-new-admin-supports-hybrid-work-arrangement">government</a>, and <a href="https://www.philstar.com/the-freeman/cebu-business/2022/05/17/2181627/itbpo-sector-hopes-new-admin-supports-hybrid-work-arrangement">some
        //                 private organizations</a> pledging their support for a flexible setup, it’s safe to say that it’s time to start
        //                 thinking about shifting to a cloud office – that is if your organization has yet to do so.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article9-img-b.png")} alt=""/>
        //                     <p>Cloud office provides business continuity</p>
        //                 </div><br /><br />
        //                 <h1>Importance of cloud office</h1>
        //                 <p>Shifting to a cloud-focused organization has its advantages and disadvantages. However, one of the
        //                     most important advantages of being cloud-based is that it ensures <a href="https://mycarrier.telkom.co.id/article/why-cloud-communication-is-essential-for-your-business">business continuity</a>. Because
        //                     anyone can connect to a unified platform with just an internet connection, the business can go usual. With the flexibility and mobility that the cloud offers, anyone in the company can take their
        //                     office anywhere. This is especially true and important nowadays where everything is fast-paced and
        //                     providing immediate solutions and access is what makes an organization thrive.</p>
        //                 <h1>Sustainability in times of global pandemic, and more</h1>
        //                 <p>A lot of organizations and companies have learned <a href="https://www.forbes.com/sites/bernardmarr/2020/03/20/why-companies-turn-to-digital-marketing-to-survive-covid-19/?sh=3b7a25ea2425">how important digital transformation</a> is. There are
        //                     many conservative companies that were forced to recognize and adapt the importance of technology
        //                     such as communication apps, cloud platforms, and more. In fact, there are studies on how the
        //                     pandemic is <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8437806/">“the great accelerator”</a> when it comes to embracing modern technologies including
        //                     business strategies.</p>
        //                 <p>When it comes to sustainability, technology such as cloud platforms provides a stable process for a
        //                     business to continue its operations despite the threat of a pandemic or anything similar that affects
        //                     the global population. Thankfully, <a href="https://www.spiceworks.com/tech/cloud/guest-article/a-post-pandemic-silver-lining-working-from-anywhere-on-the-cloud/">enterprises have acquired a mature understanding of the cloud</a>,
        //                     from cloud-first strategy to solutions, as well as how it’s important that this strategy is feasible (and
        //                     wise) because of its low-cost, agile, and scalable nature.
        //                 </p>
        //                 <p>Hybrid work, hand-in-hand with a cloud platform, is indeed the future of the work environment for the
        //                     ensured professional success of any business or organization.
        //                 </p>
        //             </>
        //         )
        //         break;
        //     case "importance-of-cloud-communications":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Importance of Cloud Communications</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article14-img-a.png")} alt=""/>
        //                     <p>Advance technology such as cloud communication is important in a workplace</p>
        //                 </div>
        //                 <p>Navigating the complexities of technology is a daily struggle. However, this becomes easier every day
        //                     because as technology advances, so does user convenience. With the existence of the <a href="https://www.cloudflare.com/learning/cloud/what-is-the-cloud/">cloud</a>,
        //                     everything that did not seem possible before became possible. Now, everything is accessible, stored,
        //                     and completely secure on the internet.</p>
        //                 <p>When the pandemic started, <a href="https://www.atlantech.net/blog/cloud-communications">more than 50% of businesses around the world shifted from the in-
        //                     office to the cloud</a>. Utilizing the cloud is strategic and important for business continuity. The same goes for companies shifting to cloud communications.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article14-img-b.png")} alt=""/>
        //                     <p>Cloud communication platforms are rich in features which raise productivity</p>
        //                 </div><br /><br />
        //                 <p><strong>Cloud communications: Defined</strong></p>
        //                 <p>The rise of cloud communications provided a lot of ease to businesses and users alike. But what
        //                     exactly is it?</p>
        //                 <p>Cloud communications is an <a href="https://www.bigcommerce.com/ecommerce-answers/what-is-cloud-communications/">internet tool that allows efficient communication via the cloud</a>. It
        //                     removes on-premise hardware that is costly and time-consuming and replaces it with a secure
        //                     platform that is cost-efficient, time-saving, and convenient to use. Cloud communications are also
        //                     <a href="https://www.commsplus.co.uk/blog/why-is-cloud-communication-important">rich with features and offer flexibility</a> for an enhanced user experience.</p>
        //                 <p><strong>Why Cloud Communications is Vital</strong></p>
        //                 <p>Cloud communication is an important tool in an entity’s <a href="https://www.valuer.ai/blog/why-cloud-communications-is-crucial-to-digital-transformation">digital transformation and innovation</a>. This
        //                     realization manifested when the pandemic started – with companies shifting to remote work and
        //                     utilizing online platforms, including VoIP, teleconference, online messaging, and more. The existence
        //                     of cloud platforms created convenience for easier collaboration which helped business continuity
        //                     despite not being able to report to the office.</p>
        //                 <p>The telecommunications market in particular <a href="https://www.commsplus.co.uk/blog/why-is-cloud-communication-important">relied heavily on traditional on-site PBX</a>, which is heavy
        //                     on costs and maintenance fees. Cloud communications changed the trajectory of the market,
        //                     enforcing big changes and innovations which bring benefits to the market and consumers alike.</p>
        //                 <p><strong>Productivity Benefits</strong></p>
        //                 <p>The increase in remote and hybrid working environments also calls for important online apps and
        //                     features. Unified communications platforms such as Telavi Cloud provides a space packed with useful
        //                     features that can increase employee productivity.
        //                     And since everything needed is in the cloud, workers and managers alike can work and receive calls
        //                     anytime and anywhere.</p>
        //                 <p>Learn more about cloud communications with Telavi Cloud’s free trial. Sign up <a href="https://4583070.extforms.netsuite.com/app/site/crm/externalleadpage.nl/compid.4583070/.f?formid=18&h=AAFdikaIZU-vVQBYbj67dxw7Cu8c3UjFdWKxnphm1DqHCA1Py70&redirect_count=1&did_javascript_redirect=T">here</a>.</p>
        //             </>
        //         )
        //         break;
        //     case "partnerships-to-enable-future-ready-businesses":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag newspr">News and PR</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Partnerships to enable future-ready businesses</h1>
        //                 <p>True to its mission of providing businesses with powerful yet affordable and customizable communication solutions, Gur Lavi Corp. (GLC) is on
        //                     track to reach greater heights as they announced new partnerships with some of the known and trusted global tech brands.</p>
        //                 <p>The company started from humble beginnings almost a decade ago and has cemented its place as one of the fastest growing
        //                     telecommunications distributors in the Philippines. One of its flagship brands is TeLavi Cloud, considered to be the only one of its kind and an all-
        //                     in communication platform in the country today.</p>
        //                 <p>GLC President and CEO Erwin Co explains that during the ongoing pandemic a lot of companies have shifted to cloud-based solutions as they
        //                     embraced new work set-up for their employees who are now doing office tasks remotely at home.
        //                     Co added, “We want to help the Filipino community. We understand how hard it is to operate a business during this pandemic, that’s why we have
        //                     created solutions and made them available at low cost to uplift entrepreneurs and help them get out of their current situations.”</p>
        //                 <p><strong>TeLavi Cloud New Partners</strong></p>
        //                 <p>As part of the company’s growth and bigger plans for the future, Co is happy to announce that they have already confirmed their partnerships
        //                     with big global tech brands Poly, Jabra, Logitech, Cisco and Yealink which are all known for their quality and excellent product and services.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article22-img-a.png")} alt=""/>
        //                 </div>
        //                 <p>Poly headsets, phones and video conferencing solutions are officially certified for Microsoft Teams to help workforces truly connect and enhance
        //                     productivity. Jabra is a top Danish brand specializing in audio equipment and videoconference systems with a wide array of products. Logitech is
        //                     one of the world’s leading manufacturers of input and interface devices for laptops and PCs and other digital products for almost 40 years and
        //                     counting. Cisco Systems, Inc. is an American multinational technology conglomerate that produces networking hardware, software,
        //                     telecommunications equipment and other high-technology services and products. Yealink is an award-winning global brand that specializes in
        //                     video conferencing, voice communications and collaboration solutions with best-in-class quality, innovative technology and user-friendly
        //                     experience.</p>
        //                 <p>Partnership with these tech giants is something that every Filipino should be proud of because this only shows that our home-grown innovations
        //                     are being recognized by internationally-known names in the industry.</p>
        //                 <p>With the said partnerships, Telavi Cloud can provide better experience as it continues to provide a unified communications and call center
        //                     solutions platform well-suited for companies with employees working from home who can now receive calls from any Android or iOS smartphone,
        //                     tablet, laptop or desktop using any web browser or an IP phone. Customers can contact your office landline numbers and the calls can be
        //                     answered by employees working at home.</p>
        //                 <p class="bordered-text">“Companies can now have a unified communication platform. It’s like a PBX in the cloud. Wherever you are, as long as you have Internet, you will
        //                     never miss any phone call from your office,” Co explained.</p>
        //                 <p>TeLavi Cloud offers (1) Cloud Telephony solutions that can increase team members’ mobility by allowing them to take important business calls even if they are not in the office;
        //                     (2) Team Messaging to centralize all business communications in one platform using any device which avoids
        //                     cluttering mobile phone storages with different apps that saves time from switching from one platform to another;
        //                     (3) Video Conferencing that can bring remote workers face-to-face with just a few clicks on any device thus boosting business continuity and savings; and
        //                     (4) Call Center
        //                     Solution which can support single location setup as well as multi-branch setup via virtual platform for your organization to any office location or
        //                     even from home.</p>
        //                 <p>On top of this, another product that GLC is proud of is the TeLavi Connect, a simple cloud service add-on to Office 365 that connects Microsoft
        //                     Teams to any on Prem or Cloud PBX in minutes allowing incoming and outgoing calls on any device using the Microsoft Teams App. It has the
        //                     ability to bring all users under one platform via Microsoft Teams for collaboration, messaging and voice across all devices. Whether using a
        //                     desktop or a mobile phone, there is no hardware or software to install because all that is to be done is to enable TeLavi Connect for users and
        //                     groups that need it. It can be integrated with any PABX System or the company can retain existing IP phones and legacy system. Plus, there is a
        //                     round-the-clock monitoring support and enterprise-grade encryption and security built-in for a safe virtual business environment.</p>
        //                 <p>While many countries have started to rise from the disruptions that were brought about by the global health crisis, the Philippines is still thriving
        //                     to overcome the challenges that the country is still facing. The good news is that there are companies that have come up with innovations that
        //                     can help both Small and Medium-sized Enterprises (SMEs) and Enterprise-level companies to fully adapt the “new normal” of doing business.</p>
        //                 <p>TeLavi Cloud can help companies achieve business continuity, savings and productivity. It is not a promise but a commitment. If you wish to avail
        //                     of the FREE 30-day no obligation trial, please visit <a href="www.telavi.com.ph">www.telavi.com.ph</a> for more details.</p>
        //             </>
        //         )
        //         break;
        //     case "telavi-cloud-a-necessary-business-continuity-plan-component":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Telavi Cloud: A necessary business continuity plan component</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article25-img-a.png")} alt=""/>
        //                 </div>
        //                 <p>The business landscape in these uncertain times is quite difficult to navigate. Many institutions face various challenges brought about by
        //                     unexpected emergency situations like Covid-19. They must adapt to the ever-changing climate in order to stay afloat in the new normal.</p>
        //                 <p>To be able to maintain operations smoothly during times of crises, an organization, whatever size, revenue or in whatever industry, must have a
        //                     strong business continuity plan in place, otherwise the business will suffer the consequences.</p>
        //                 <p>A business continuity plan, or BCP, is simply advanced preparations or course of action to ensure an enterprise is capable of responding properly
        //                     to emergencies or disasters that may or may not occur in the future. It ensures the organization is able to sustain its essential business functions
        //                     during critical times.</p>
        //                 <p>CEO and President of Gur Lavi Communications, Erwin Co, further defines BCP as “a set of carefully crafted policies and procedures to help a
        //                     company address any potential threat which can lead to business closure, or business interruptions. And now that Covid cases are increasing
        //                     exponentially, I think it is important for companies to have a BCP in place to ensure that they are able to remain operational during these times of
        //                     crisis. Normally, these BCPs are tailor-fit to each company, helping them save money, or at least lose less money, while looking after the safety of
        //                     their employees.”</p>
        //                 <p>Part of a business continuity plan identifies means of communicating with clients, suppliers, and other vendors and entities to continue providing
        //                     proper information and support to the enterprise.</p>
        //                 <p>Telavi Cloud, the latest in cloud communication services, is the essential business solution to various challenges a business faces. Powered by
        //                     Gur Lavi Communications, it is a unique and unified communications system that helps enable enterprises to persevere and carry on, no matter
        //                     how trying the circumstances may be.</p>
        //                 <p>Jayson Lo, entrepreneur, author, motivational speaker, and Telavi Cloud ambassador explains, “Telavi Cloud is a game-changer in business
        //                     communications. It brings us flexibility in our workplace and it also helps us in terms of having seamless communication. So, I recommend this to
        //                     all businesses who want to change with the changing times and embrace it as our permanent normal. Business continuity is made easy and
        //                     affordable with Telavi Cloud.”</p>
        //                 <p>Asked how a lack of business continuity plan affects an organization, Erwin Co opines that without a BCP, companies would be vulnerable to
        //                     economic harm.</p>
        //                 <p>Without a BCP in place, Erwin Co believes that all stakeholders will be affected. Starting with customers, they might not be able to reach the right
        //                     person when calling the company, whether for a sales inquiry or a request for support. The company may also not be able to provide quality
        //                     customer service because the manufacturing or ordering processes might cease, or shipments may not arrive on time. Without the right tools,
        //                     the employees might also not be able to perform their duties efficiently.</p>
        //                 <p>Prior to the pandemic, he thinks that companies have more room to commit mistakes because things were running relatively smoothly and profits
        //                     were up without much threats disrupting the business. However, with the pandemic, he says that companies have to adapt quickly or else they
        //                     won’t be able to respond to an emergency situation and this may affect the company’s productivity.</p>
        //                 <p>For him, it is vital to have a good business continuity plan. “The first step would be to operate the business like the crisis never happened. A good
        //                     plan should help the company mitigate or reduce the impact of any threat. So the best case scenario really is if a company is able to maintain its
        //                     operations and create value for its shareholders.”</p>
        //                 <p>Erwin Co further explains that it’s crucial to “determine what the company’s non-negotiables are. From there it is just a matter of creating backup
        //                     plans to those that you cannot live without.”</p>
        //                 <p>Jayson Lo agrees that having a good BCP is like building a house with a really strong foundation.</p>
        //                 <p>A dependable component of a company’s BCP is Telavi Cloud. They support employees working remotely by providing a safe and secure way to
        //                     access data even when the corporate headquarters is unable to operate. They enable companies to provide secure remote access to their
        //                     employees while eliminating productivity killers such as virus outbreaks</p>
        //                 <p>Gur Lavi, the people behind Telavi Cloud has been serving a lot of industries supplying and installing PBX before the pandemic hit. They’ve been
        //                     dreaming of offering a cloud-based PBX to make it more affordable and accessible to people, but the market wasn’t ready yet. People were
        //                     hesitant because their internet connections might not be stable enough to handle voice traffic, thinking that they might lose both their internet
        //                     and telephone services and would not be able run their businesses properly.</p>
        //                 <p>Times have changed, and with a year of video conferencing and the increase of working remotely, companies are now more accepting of the idea
        //                     of a hosted PBX. Gur Lavi then saw the need and proceeded with their plans of offering the cloud-based PBX with the significant spike in
        //                     inquiries and companies were already looking for alternatives when they were having challenges in their operations.
        //                     Telavi Cloud can help businesses stormproof their operations, address issues, and pinpoint potential points of failure. They are offering the
        //                     market the alternative for their voice telephony that is telco grade. It is a unified communication platform that enables its users to make and
        //                     receive calls wherever they are.</p>
        //                 <p>Companies are given the ability to transfer landline calls to employees whether these calls are to the Telavi mobile app, calls can be transferred
        //                     to Microsoft Teams or to an IP phone, to any web browser. Whether employees are at home, with a laptop or a desktop, they can continue to
        //                     operate their business.</p>
        //                 <p>Telavi also offers more than just simple voice routing. From transferring calls, they also do team messaging, video conferencing, and
        //                     collaboration. Companies are then able to continue to provide their service to their customers.</p>
        //                 <p>Erwin Co emphasizes, “Telavi Cloud is a must-have, non-negotiable business communication platform that can provide companies or enable
        //                     them to drag their business anywhere in the world. So Telavi Cloud enables businesses to be truly mobile.”</p>
        //                 <p>In these uncertain times, there is no assurance when companies would be able to ask their employees back to their physical office. Even with the
        //                     vaccine, it seems like most would be continuing to work remotely or work from home even for the next year or two. That is why it is best to make
        //                     Telavi Cloud part of your business continuity plan.</p>
        //             </>
        //         )
        //         break;
        //     case "telavi-cloud-brings-office-productivity-to-the-next-level":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Telavi Cloud Brings Office Productivity to the Next Level</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article24-img-a.png")} alt=""/>
        //                 </div>
        //                 <p>Manila, Philippines - The business landscape has been evolving rapidly, driven by the worldwide pandemic. These precarious times call for
        //                     increased flexibility, mobility, with the goal of growing productivity. Enterprises must encourage their workforce to adapt quickly or be left behind.
        //                     One of these innovative business shifts is the remote work setup.</p>
        //                 <p>The remote work model or the adoption of a mobile workforce has been steadily increasing over the years. With the onset of the pandemic in
        //                     2020, this remote work arrangement has gained even more popularity among enterprises wanting to thrive in these uncertain times while keeping
        //                     their people safe. Upwork’s 2020 Future Workforce Report reveals that remote workers in the next five years is expected to be nearly double
        //                     what it was before Covid-19.</p>
        //                 <p>A unique set of communication challenges has risen when the workforce was compelled to scatter and exchange their office desks for home
        //                     offices. This issue is also shared by businesses with satellite or multiple office locations.</p>
        //                 <p>The time is ripe for businesses to bring office communications to the next level, address the changing needs of the workforce, and future-proof
        //                     the organization.</p>
        //                 <p>CLOUD COMMUNICATIONS</p>
        //                 <p>The most viable solution is cloud communications. This provides your staff with an arsenal of vital applications and tools conveniently found on
        //                     the same platform, wherever their location may be or whatever device they use.</p>
        //                 <p>TELAVI CLOUD</p>
        //                 <p>One of the fastest-growing players in the field is Telavi Cloud, a unified communications and call center solutions provider offering innovative and
        //                     relevant products and services in the market today. They enable users to increase productivity through this platform whenever, wherever and
        //                     promote effective customer engagement. They aim to help organizations lower down operational costs with their highly affordable, flexible, and
        //                     all-in-one business solutions packages.</p>
        //                 <p>Jonathan Yabut, winner of the hit Asian reality TV show, The Apprentice Asia, a best-selling author, and motivational speaker, has seen the
        //                     benefits, and shares, “Telavi Cloud is an all-in-one technology suite that takes away the dilemma of organizations having to choose between
        //                     security and safety. Because it is in the cloud, all of the information, the database, and the platforms of your company can be accessed in the
        //                     comfort and the safety of your employees’ homes.”</p>
        //                 <p>VIDEO CONFERENCING</p>
        //                 <p>Step up your team collaboration by holding virtual meetings via Telavi video conferencing and screen sharing wherever the attendees may be.
        //                     This increases understanding by permitting each one to experience the nuances of face-to-face interaction while working on files together.</p>
        //                 <p>CALL FORWARDING</p>
        //                 <p>Telavi Cloud also allows call forwarding. You can direct inbound calls to the right person as it comes through. If it goes unanswered, you can set
        //                     where the call goes next, whether to another department or another branch.</p>
        //                 <p>VOICEMAIL</p>
        //                 <p>You don’t have to miss an important call again with the Telavi app. It links you to your office phone number, no matter where you may be. It’s like
        //                     working in your office even when you’re on the go. Telavi Cloud also has your off-hours covered. For crucial calls coming in beyond office hours,
        //                     you can opt for voicemail service so you don’t let opportunities slip by just because of the difference in time zones.</p>
        //                 <p>Jonathan also appreciates how flexible Telavi Cloud is. “Whether you are an enterprise composed of thousands of employees, or an SME with
        //                     only 20 or 30 employees, Telavi Cloud allows itself to be flexible in customizing according to the needs of your people,” expounds Jonathan.</p>
        //                 <p>SCALABILITY</p>
        //                 <p>The ebb and flow of your business may create the need for more employees or hiring of temps, entailing additional lines or phone numbers during
        //                     peak season. Non-peak periods then require enterprises to downsize again to manage costs.</p>
        //                 <p>With a traditional PBX or on-premise phone system, you incur additional costs for hardware and take up precious time in setting-up the system.
        //                     Switching to Telavi Cloud’s hosted PBX system lessens the standard upfront hardware costs of traditional PBX solutions but lets you enjoy their
        //                     features and benefits. You only need to invest in a softphone or software, and relatively less phone equipment.</p>
        //                 <p>Jonathan lauds Telavi Cloud’s accessibility when it comes to costs. “You don’t need to buy infrastructure, you don’t need to buy hardware, you
        //                     can even use it on a pay-per-use basis depending on the capabilities and the budget of your organization.”</p>
        //                 <p>With Telavi Cloud You can opt to just pay on a quarterly basis and you’re all set. Or you can easily upgrade your package during peak seasons
        //                     when you have more people onboard, then scale back down if the need arises. It’s convenience all around.</p>
        //                 <p>With everything changing so quickly around us, it’s best for your business to adapt to the new normal and ensure your people stay connected
        //                     with each other and your clients.</p>
        //                 <p>Let Telavi cloud bridge the gaps your business is dealing with, whether it’s productivity or engagement, Telavi Cloud is the solution you need
        //                     today.</p>
        //                 <p>For more information or if you want to subscribe to Telavi Cloud services, visit www.telavi.com.ph, email them at <a href="">info@gurlavi.com.ph</a>, or call
        //                     them at <a href="">+632-8967-0580</a>.</p>
        //                 <p>=================================================================================</p>
        //                 <p>PRESS RELEASE - Jonathan Yabut</p>
        //                 <p>PRODUCTIVITY AND ENGAGEMENT</p>
        //                 <p>Manila, Philippines - Business landscape has been evolving rapidly, driven by the worldwide pandemic. These precarious times call for increased
        //                     flexibility, mobility and of course, productivity. Enterprises must encourage their workforce to adapt quickly or be left behind. One of these
        //                     innovative business shifts is the remote work setup.</p>
        //                 <p>The remote work model or the adoption of a mobile workforce has been steadily increasing over the years. With the onset of the pandemic in
        //                     2020, this remote work arrangement has gained even more popularity among enterprises wanting to thrive during lockdowns while keeping their
        //                     people safe. Upwork’s 2020 Future Workforce Report reveals that remote workers in the next five years is expected to be nearly double what it
        //                     was before Covid-19.</p>
        //                 <p>A unique set of communication challenges has arisen when the workforce was compelled to scatter and exchange their office desks for home
        //                     offices or dinner tables. This issue is also shared by businesses that have satellite or multiple office locations.</p>
        //                 <p>It’s high time businesses bring office communications to the next level, address the changing needs of the workforce, and future-proof the
        //                     organization.</p>
        //             </>
        //         )
        //         break;
        //     case "telavi-cloud-centralizes-cardona-rizals-internet-and-voice-connectivity-to-improve-its-public-service-and-delivery-of-education":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag newspr">News and PR</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>TeLavi Cloud centralizes Cardona Rizal’s internet and voice
        //         connectivity to improve its public service and delivery of education</h1>
        //                 <p>True to its mission of providing businesses with powerful yet affordable and customizable communication solutions, Gur Lavi Corp. (GLC) is on
        //                 track to reach greater heights as they announced new partnerships with some of the known and trusted global tech brands.</p>
        //                 <p>The company started from humble beginnings almost a decade ago and has cemented its place as one of the fastest growing
        //                     telecommunications distributors in the Philippines. One of its flagship brands is TeLavi Cloud, considered to be the only one of its kind and an all-
        //                     in communication platform in the country today.</p>
        //                 <p>GLC President and CEO Erwin Co explains that during the ongoing pandemic a lot of companies have shifted to cloud-based solutions as they
        //                     embraced new work set-up for their employees who are now doing office tasks remotely at home.
        //                     Co added, “We want to help the Filipino community. We understand how hard it is to operate a business during this pandemic, that’s why we have
        //                     created solutions and made them available at low cost to uplift entrepreneurs and help them get out of their current situations.”</p>
        //                 <p><strong>TeLavi Cloud New Partners</strong></p>
        //                 <p>As part of the company’s growth and bigger plans for the future, Co is happy to announce that they have already confirmed their partnerships
        //                     with big global tech brands Poly, Jabra, Logitech, Cisco and Yealink which are all known for their quality and excellent product and services.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article23-img-a.png")} alt=""/>
        //                     <p>MJ Hernandez, GLC telecom project lead; Cardona Mayor Teodulo C. Ocampo; Cristine Amores, PLDT relationship manager.</p>
        //                 </div>
        //                 <p>Known as a fishing community, the municipality of Cardona in the Rizal province is gearing towards centralization of its Internet and voice
        //                     connectivity for improved public service, official business, and delivery of education in today’s wave of technology.</p>
        //                 <p>Cardona Mayor Teodulo C. Campo said, “This initiative will positively impact not only our business and public service sector but also the
        //                     educational sector especially that Internet is a necessity right now because of the online classes. Also, with this program, certain processes in
        //                     local government will be alleviated.”</p>
        //                 <p>Mayor Campo added that with the innovative project, it will be easier for their LGU to reach out with their constituent from their 18 barangays, 11
        //                     of which are on the mainland and 7 are on Talim Island because the technology that they are acquiring can provide updates done as quickly as
        //                     possible and is efficient and consistent at the same time.</p>
        //                 <p>Just One Number to Remember</p>
        //                 <p>Gur Lavi Corp. (GLC), one of the fastest growing telecommunications distributors in the country, will provide the technology for this ground-
        //                     breaking initiative in Cardona using one of its flagship brands – TeLavi Cloud – considered to be a pioneering all-in communication platform in the
        //                     Philippines today.</p>
        //                 <p>GLC President and CEO Erwin Co believes, “Having Telavi Cloud gives huge advantage for Municipality of Cardona. It will not just improve the
        //                     communication inside their organization but also their collaboration. They can join an audio conference call with a single click, use individual and
        //                     group messaging to communicate with colleagues quickly and easily, and it creates workspace for teams where they can chat in real time and
        //                     share important information especially during calamities.”</p>
        //                 <p>Residents of Cardona only need to remember one number to call to be able to reach the agencies and the offices of the municipality. They just
        //                     have to dial 8539-2399.</p>
        //                 <p>Co also said that employees of Cardona LGU can stay connected with a robust Telavi Cloud App. Alternatively, they can deploy IP phones at
        //                     home during this era of remote work and can still be reached by their constituents by dialing their government office landline number.</p>
        //                 <p>Project Lead MJ Hernandez explains the other benefits that their technology can provide, “In GLC, it is always our mission to support the
        //                     community. We understand the challenges that everyone is facing now that’s why we created effective and user-friendly solutions that will
        //                     connect people wherever they are to institutions or organizations the fastest way possible.”</p>
        //                 <p>Improving Lives in Cardona</p>
        //                 <p>Mayor Campo assures that the Cardona LGU has always been in fact interested in having a centralized Internet and voice connectivity, so when
        //                     they received a proposal, he immediately acted on this because he knows that this project will improve the lives of his constituents.</p>
        //                 <p>“To my constituents here in Cardona, I hope that this initiative shall ease your daily plight when it comes to Internet connectivity and swear that
        //                     there will be more projects in the future that will be geared on improving the lives of everyone in here. So, let us continue our support for our
        //                     municipal leaders from yours truly up to the vice mayor, councilors, barangay captains, and barangay social workers,” Mayor Campo said.</p>
        //                 <p>The Cardona Telavi Cloud project and the centralization of voice and data system are seen as just the start of more innovative projects in the
        //                     province of Rizal.</p>
        //                 <p>Hernandez emphasized that GLC in partnership with PLDT is always trying to serve LGUs from Barangay as the smallest administrative division to
        //                     Municipality up to the Provincial Government and are now coordinating and having meetings with different municipalities of Rizal to the Provincial
        //                     Government to enhance their infrastructure and communication tool.</p>
        //                 <p>“Our aim to any communication system is to ensure clarity of information and thus pave the way for a clutter-free project. The end result should
        //                     always be increased productivity and ultimately profits, but the journey to get there should be as straightforward as possible, Hernandez said.</p>
        //             </>
        //         )
        //         break;
        //     case "the-future-of-hybrid-work":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag newspr">News and PR</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>The Future of Hybrid Work</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article10-img-a.png")} alt=""/>
        //                     <p>What’s in store with a flexible work setup?</p>
        //                 </div>
        //                 <p>In the age of continuous innovation and connectivity, hybrid work brings a new perspective to every
        //                     company’s work setup. Remote work took center stage when the pandemic started. With the
        //                     increased vaccination initiatives, companies adopted a <a href="https://www.forbes.com/sites/joemckendrick/2021/05/30/remote-work-evolves-into-hybrid-work-and-productivity-rises-the-data-shows/?sh=548409694825">more flexible approach</a> to work setups. In fact,
        //                     several IT and business leaders have called <a href="https://www.hrdive.com/news/hybrid-work-new-normal-business-leaders-say/626557/">hybrid work the “permanent solution”</a> for their businesses.</p>
        //                 <p>But the question still remains: will hybrid work stay or is it just a bubble that will eventually burst after
        //                     its “hype” dies down?</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article10-img-b.png")} alt=""/>
        //                     <p>Hybrid work allows flexibility not just in employees but in companies, too</p>
        //                 </div>
        //                 <h1>Hybrid work is a great opportunity</h1>
        //                 <p>The flexibility of a hybrid work setup gives something that is very precious to employees: Time. They
        //                     have the opportunity to do more with their time in a flexible work environment, letting them have
        //                     better well-being. This helps their <a href="https://www.leitz.com/en-ax/blog/leitz/workleitzbalance/7-challenges-and-benefits-of-hybrid-working/">productivity</a> – a huge benefit for companies as well. Hybrid work
        //                     welcomes a <a href="https://www.seattletimes.com/opinion/the-future-of-work-is-hybrid-seamless-and-inclusive/#:~:text=The%20future%20of%20work%20is%20a%20hybrid%20environment%2C%20powered%20by,future%20workplace%20work%20for%20everyone.">great opportunity</a> because in “ <a href="https://www.amazon.com/Built-Last-Successful-Visionary-Essentials/dp/0060516402">embracing the genius</a> of the ‘and’ rather than the tyranny
        //                     of the ‘or’”, a holistic approach can be found for the company’s vision. Only by adopting new
        //                     innovations and setups can the company grow healthily for the coming years.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article10-img-c.png")} alt=""/>
        //                     <p>Prioritizing health and safety and showing empathy is integral in a work place</p>
        //                 </div>
        //                 <h1>Emphasizing empathy and safety at work</h1>
        //                 <p>As we move forward, companies will need to focus on their employees’ health and safety, more so
        //                     than before. Increasing empathy towards the workers provides a better work environment. In the
        //                     event of out-of-office set-up, telephony solution services such as <a href="https://telavi.com.ph/?utm_source=site&utm_campaign=the-future-of-hybrid-work">Telavi</a> helps communication easier
        //                     by connecting calls to devices as if the employee never left the office. Technologies such as these
        //                     provide a much easier transition to remote or hybrid work setup for business continuity.</p>
        //                 <p>Aside from having remote work days in a flexible setup, companies will benefit from the creation
        //                     of <a href="https://www.workinsync.io/hybrid-workplace-an-insight-into-a-leaders-perspective/">modern infrastructure and frictionless administration</a> by providing smart workspaces that can help
        //                     promote safety when working onsite. Companies should provide no-contact technology or services
        //                     (e.g., online tools and services for reserving meeting rooms, automatic sanitation of office spaces,
        //                     etc.) to create a safe zone for all. Emphasizing health and safety promotes empathy in a workspace
        //                     which results in a better working environment.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article10-img-d.png")} alt=""/>
        //                     <p>Is it beneficial for companies to continue the hybrid work setup?</p>
        //                 </div>
        //                 <h1>Will hybrid work remain even in the future?</h1>
        //                 <p>Having established that hybrid work is not just <a href="https://telavi.com.ph/5-common-myths-about-hybrid-work/">borne out of the pandemic</a>, flexible working
        //                     arrangements will not just die down – in fact, it will most likely be the <a href="https://www.globenewswire.com/en/news-release/2022/06/23/2468070/0/en/STUDY-With-71-of-Companies-to-Make-Remote-or-Hybrid-Work-Policies-Permanent-IT-Infrastructure-Investment-Continues-to-Increase.html">new norm</a>.</p>
        //                 <p>As the <a href="https://www.theglobeandmail.com/business/article-zoom-cio-expects-longevity-of-hybrid-work-due-to-emphasis-on-work-life/">new generation</a> of people join the workforce, they are more likely to prefer a job that offers a
        //                     work-life balance. Holistically, flexible work arrangements can help avoid turnarounds, especially for
        //                     younger workers. Hybrid work is indeed here to stay.</p>
        //             </>
        //         )
        //         break;
        //     case "unified-solutions-to-save-money-in-the-new-normal":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>Unified Solutions To Save Money In The New Normal</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article26-img-a.png")} alt=""/>
        //                 </div>
        //                 <p>The pandemic has changed so many things including the way companies operate.</p>
        //                 <p>Edric Mendoza, self-titled change-maker, entrepreneur and former news anchor of ANC’s “On the Money”, shares the top financial problems
        //                     companies faced at the start of the pandemic.</p>
        //                 <p>The first is people. Businesses had to make sure their employees as well as their customers were safe. They had to consider the protocols and
        //                     the expenses related to healthcare. Second is logistics. Companies had to carry on maintaining overhead costs, the facilities, rentals, even
        //                     transportation. The third is the actual product and service. Industries with products and services that could not adapt well had to scale back on
        //                     their product line or stop their services.</p>
        //                 <p>Part of the solution is remote working. With employees working from home, employers are left to look for tools to make this new set-up work as 
        //                     efficiently in the new normal. In order for productivity to be maintained if not increase, collaboration needs to be as effective now as it was face-
        //                     to-face while maximizing savings.</p>
        //                 <p>With the implementation of the work-from-home scenario, the company savings and ROI are affected depending on how it works with the
        //                     company’s business model and how well the business can adapt. That would be a clear source of savings.</p>
        //                 <p>An issue that arises from this new set-up would be connectivity. The success of the work-from-home model on bottomline savings and returns
        //                     depends if employees are able to connect properly. It makes it more inefficient for the company and has major cost implications.</p>
        //                 <p>The question is, is there a solution for this? Edric Mendoza has found out that there definitely is a solution to maximizing business revenue. As he
        //                     sees it, the latest cloud solution can maximize profit and save your budget while ensuring your employees are productive and engaged.</p>
        //                 <p>This game-changing solution is Telavi Cloud, a unified communications platform. It’s an all-in-one solution that gathers the tools to keep people in
        //                     touch: voice calls, video calling, web conferencing, and more. It’s decentralizing communications and giving greater access to all.</p>
        //                 <p>Birthed during the pandemic, founder, CEO and president of Gur Lavi, Erwin Co, saw a need for it. “For more than 5 years, we’ve dreamt of
        //                     putting this up. But you have to adjust to the market. If they’re not ready for it, they’re not going to sign up. This time around things are different. I
        //                     think a lot of companies are now looking for technology to help them bridge this, to get over this situation that we’re in.”</p>
        //                 <p>First on the list of savings is on infrastructure. Since it is a service you subscribe to, there is no need to maintain your own infrastructure or
        //                     center, or even hire your own technical engineers to keep your telephony system running.</p>
        //                 <p>Telavi Cloud also updates and continuously adds more features to their platform so companies get the most for their money. Companies also
        //                     don’t need to invest in IP phones or headsets as they can use the web browser or mobile app alone. Expenses can be kept at a minimum if they
        //                     want to.</p>
        //                 <p>Yes, you save on costs, when the CapEx is turned into an operating expense and the logistics becomes a lot simpler. Since it’s cloud based, a
        //                     business can have Telavi Cloud systems even in less than a week.</p>
        //                 <p>With Telavi Cloud, the cost and risk of infection from personal contact is eliminated, as equipment is sent to the company, who plugs it in
        //                     themselves. Telavi walks them through the process, but all done remotely. It’s really easy and safe for everyone involved.</p>
        //                 <p>Adapting by individual users is fairly easy as well, as it’s just another straightforward app that you need to use. It’s a complete, all-in-one solution
        //                     where you can message, you can call with the dial pad, but also do video conferencing.</p>
        //                 <p>With Telavi Cloud, businesses can be run no matter where employees are, remotely working and safe in their own homes. In the past where
        //                     technology was limited, there was a need for an on-premise system, now you can transfer all these calls to them and serve your customers with
        //                     Telavi Cloud.</p>
        //                 <p>Edric Mendoza adds that Telavi Cloud can be an answer to protecting company finances in various ways. Yes, Telavi Cloud can strengthen the
        //                     work-from-home scenario that many companies need at this time. It can also help in the learning curve. Some companies might want to do it
        //                     themselves, but the money and time spent can impact expenses.</p>
        //                 <p>The key is to be able to maximize all these savings or financial gains with these tech solutions. Telavi Cloud is reliable, with strong customer
        //                     service and support when things don’t work out. They own their own servers which allows integration and greater control and quicker response
        //                     to issues that companies might have as they’re using the technology.</p>
        //                 <p>“Another feature when it comes to financial savings and upsides, in a cloud scenario like that, even maintaining people, having to have my own
        //                     team, and to be able to oversee and manage the infrastructure would be a big cost savings because it’s done by the third party and all the cost
        //                     related to maintaining and managing employees related to that side of the business or operations,” explains Edric Mendoza.</p>
        //                 <p>Eric Co further adds that “In trying times like this, there’s always an opportunity to shine as leaders. We have the social responsibility and we
        //                     carry the burden of supporting our people and the community. So when you find a solution this big and this revolutionary, it is our responsibility
        //                     to bring it to market and make it available to as many as possible. A lot of people are suffering because of this crisis that they cannot run their
        //                     businesses or operate their business as before, that’s why for us, if this is something that can help them cope with the situation, then we’re
        //                     definitely here to help.</p>
        //                 <p>Choose Telavi Cloud. The smart way to protect your business and the right way to be new normal-adapted.</p>
        //             </>
        //         )
        //         break;
        //     case "what-is-cloud-office-and-is-it-necessary":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>What is Cloud Office and Is It Necessary?</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article15-img-a.png")} alt=""/>
        //                     <p>Cloud office and its importance to any business</p>
        //                 </div>
        //                 <p>Many companies have resorted to shifting to a cloud office in this ever-changing work landscape. But
        //                     what exactly is a cloud office, and is it necessary for businesses to change to a cloud office? What
        //                     are the benefits it will give the company?</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article15-img-b.png")} alt=""/>
        //                     <p>Time to shift to Cloud Office</p>
        //                 </div>
        //                 <p><strong>What is a Cloud Office?</strong></p>
        //                 <p>No, it’s not a literal office situated within the clouds.</p>
        //                 <p>A cloud office is a secure online platform where you can store documents, messages, emails,
        //                     calendars, and other modes of collaboration meant for a team or company’s business use. Cloud
        //                     office is also a <a href="https://nuedusec.com/cloud-office.php">suite of software as a service
        //                         SaaS</a>. With this, work is possible anywhere you are since data and communication tools are all in the Cloud.</p>
        //                 <p>Additionally, it was only <a href="https://www.bettercloud.com/monitor/cloud-office/">two decades ago</a> that cloud services began entering offices. However, as
        //                     technological advancements happen, most companies have now hopped into the Cloud system,
        //                     especially since the pandemic started.</p>
        //                 <p><strong>Benefits of a Cloud Office</strong></p>
        //                 <p>There are <a href="https://nuedusec.com/cloud-office.php">a lot of benefits</a> that can be cultivated in shifting to a Cloud office. Some of these are:</p>
        //                 <p><strong>Cost-effective & Affordable</strong> - Cloud-based services are mainly based on <a href="https://www.syvantis.com/blog/why-the-cloud-is-more-cost-effective-than-your-servers">storage space and
        //                     processing power</a>. Management, maintenance, and operating costs are all included within an
        //                     affordable fee compared to on-premise solutions.</p>
        //                 <p><strong>Unlimited storage</strong> - A <a href="https://nuedusec.com/cloud-office.php">Cloud office</a> offers unlimited storage space for files, while some offer storage space based on the number of users.</p>
        //                 <p><strong>Data security</strong> - Several Cloud office platforms offer tight security to keep sensitive data away from any breaches.</p>
        //                 <p><strong>Business continuity</strong> - In case of accidents, unforeseen incidents, and even a global pandemic, Cloud
        //                     offices ensured business continuity. It allowed companies to restore data, and collaborate anytime
        //                     and anywhere, to keep the company going business as usual.</p>
        //                 <p>Of course, there are challenges in completely shifting from on-premise to a Cloud office. However,
        //                     there are a lot of proven benefits that could prove that a Cloud office is worth a try. Apps such as
        //                     TeLavi Cloud creates a Cloud office that could provide these benefits that your organization might
        //                     just need. Because in these changing times, there is always a need to digitally transform for business
        //                     continuity.</p>
        //             </>
        //         )
        //         break;         
        //     case "what-is-hybrid-work-and-why-do-employees-prefer-it":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>What is hybrid work and why do employees prefer it?</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article11-img-a.png")} alt=""/>
        //                     <p>Why do employees prefer a hybrid work setup?</p>
        //                 </div>
        //                 <p>Remote work has taken over our lives for the past two years since the pandemic started.</p>
        //                 <p>With quarantine protocols and lockdowns in place, a lot of employees had to forego their office desks and daily chit-chats with their colleagues...
        //                     to a more private and quiet work-from-home setup.</p>
        //                 <div class="grid-container">
        //                     <div class="left">
        //                         <div className="image-container">
        //                             <img src={require("../assets/images/article-img/article11-img-b.png")} alt=""/>
        //                         </div>
        //                     </div>
        //                     <div class="right">
        //                         <p>Two years into the pandemic, we’re left to think: what’s next for
        //                             employees?</p>
        //                         <p>Should we return to the office in a 100 percent capacity or is work-
        //                             from-home here to stay?</p>
        //                         <p>But as a lot of us adjust to the new normal, so did the evolution of
        //                             various work setups. With restrictions slowly lifting, and as the
        //                             economy opens back up, a lot of companies have adopted a hybrid
        //                             work setup.</p>
        //                         <p>Hybrid work is a new work setup that allows employees to <a href="https://www.gallup.com/workplace/390632/future-hybrid-work-key-questions-answered-data.aspx">spend a part
        //                             of their week working remotely and a part in the office.</a></p>
        //                         <p>But hybrid work does not necessarily mean that it’s just exclusively
        //                             working at the office and at home. “
        //                             (Hybrid work) also means business
        //                             travels, taking calls outside the office, and doing work while on
        //                             vacation,” says Gur Lavi Corp. CEO Erwin Co in an <a href="https://www.youtube.com/watch?v=EjBPUOKtzrE&t=72s&ab_channel=CNNPhilippines">interview with CNN
        //                                 Philippines.</a></p>
        //                         <p>But why do employees prefer hybrid work more than just choosing
        //                             exclusively between remote work and in-office work? Well, it’s more on
        //                             work-life balance, rather than anything else.</p>
        //                     </div>
        //                 </div>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article11-img-c.png")} alt=""/>
        //                     <p>Companies are slowly adopting the new norm of a hybrid workplace</p>
        //                 </div>
        //                 <p>Based on the IBPAP Industry Survey earlier this year, around 91 percent of Filipino employees prefer a hybrid work model.</p>
        //                 <p>In fact, according to <a href="https://www.gallup.com/workplace/390632/future-hybrid-work-key-questions-answered-data.aspx">Gallup</a>, the future of the hybrid setup is still up in the air. However, they have listed <strong>reasons why people prefer the hybrid
        //                     model are:</strong></p>
        //                 <div class="grid-container">
        //                     <div class="left">
        //                         <ul>
        //                             <li>
        //                                 <p>To avoid commute time</p>
        //                             </li>
        //                             <li>
        //                                 <p>Better for their wellbeing</p>
        //                             </li>
        //                             <li>
        //                                 <p>Flexibility to balance family needs or other obligations</p>
        //                             </li>
        //                             <li>
        //                                 <p>Option to work in-person with co-workers</p>
        //                             </li>
        //                             <li>
        //                                 <p>Feel more productive and connected to the organization</p>
        //                             </li>
        //                         </ul>
        //                         <p>According to a <a href="https://news.microsoft.com/en-ph/2022/04/18/microsoft-announces-new-research-and-technology-to-make-hybrid-work-work-in-the-philippines/">Microsoft Work Trend report</a>, 69 percent of leaders in the
        //                             Philippines say that their companies are planning a return to full-time on-
        //                             site work in the year ahead.</p>
        //                         <p>However, with the continuous increase in gasoline prices, COVID cases,
        //                             and traffic congestion – a hybrid setup might just be here to stay.</p>
        //                     </div>
        //                     <div class="right">
        //                         <div className="image-container">
        //                             <img src={require("../assets/images/article-img/article11-img-d.png")} alt=""/>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </>
        //         )
        //         break;
        //     case "what-is-telephony-and-how-does-it-help-productivity":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag servicesspotlight">Service Spotlight</span>
        //                     {/* <span className="article-date">August 18, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>What is Telephony and How Does it Help Productivity?</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article16-img-a.png")} alt=""/>
        //                     <p>Telephony provides solutions to employee productivity</p>
        //                 </div>
        //                 <p>You might have seen the word “telephony” several times and wondered what it is. Is telephony a new
        //                     concept? Would it really help with employee productivity?</p>
        //                 <p>Let’s break down the <a href="https://www.merriam-webster.com/dictionary/telephony">definition</a> of telephony and what it could mean to your organization.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article16-img-b.png")} alt=""/>
        //                     <p>What is telephony and can it really help the business?</p>
        //                 </div>
        //                 <p><strong>Telephony: Defined</strong></p>
        //                 <p>Telephony, by definition, is the use or operation of an apparatus to transmit sounds as electrical
        //                     signals. Traditionally, telephony is a field of technology that involves the <a href="https://www.mitel.com/features-benefits/telephony">electronic transmission of
        //                     voice</a> between two distant parties.</p>
        //                 <p>However, as time passed, it evolved from just voice transmission to fax, internet calls, text messages,
        //                     voice mails, video conferencing, or video calls using various devices. In this context, telephony is also
        //                     frequently used to refer to computer hardware, software, and computer network systems which we
        //                     can specifically refer to as <a href="https://www.mitel.com/features-benefits/telephony">internet telephony or VoIP.</a></p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article16-img-c.png")} alt=""/>
        //                     <p>Does telephony help with productivity?</p>
        //                 </div>
        //                 <p><strong>How Telephony Helps Employee Productivity</strong></p>
        //                 <p>Telephony bridges the gap with how fast communication happens. All of these telephony systems
        //                     meant for organizations have helped speed up communication from one place to another in only a
        //                     matter of minutes. In particular, there are
        //                     <a href="https://www.techtarget.com/searchunifiedcommunications/definition/Telephony">(4) four types of telephony systems for the enterprise</a>.
                            
        //                     These are:</p>
        //                 <ol>
        //                     <li>
        //                         <p><strong>On-Premise PBX</strong> - Acts as an in-house center</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>VoIP PBX</strong> - Creates a private phone network within a business using the internet or LAN</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Hybrid PBX</strong> - Uses both On-Premise PBX and IP telephony</p>
        //                     </li>
        //                     <li>
        //                         <p><strong>Cloud VoIP</strong> - A cloud-based VoIP service provided by a third party</p>
        //                     </li>
        //                 </ol>
        //                 <p>Cloud-based VoIP, when used as a part of a unified communications solutions platform like <a href="https://telavi.com.ph/">Telavi</a>,
        //                     makes it easier for employee communication and business continuity. Because it’s more modernized
        //                     than traditional phones, <a href="https://www.businessnewsdaily.com/15333-cloud-based-phone-system.html">cloud phones</a> are hosted through internet systems, which makes
        //                     communication faster and cheaper. UC Services like Telavi can help extend your office anywhere you
        //                     are in the world, so you can do your business anytime when necessary.</p>
        //                 <p>With the continuous innovation and improvement of technology, it’s important to always be
        //                     connected wherever you are. Rest assured that with communication technology such as telephony
        //                     remains to evolve, your business is in the right place.</p>
        //             </>
        //         )
        //         break;
        //     case "what-should-i-look-for-in-a-voip-solution":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>What should I look for in a VoIP solution?</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article17-img-a.png")} alt=""/>
        //                     <p>Communication is important for business continuity</p>
        //                 </div>
        //                 <p>A key part of maintaining business continuity is communication. With workplaces adopting hybrid and
        //                     remote work setups, VoIP systems become a necessity to ensure that a company is still doing
        //                     business as usual. Before choosing which VoIP to get, find out what are the necessary things to look
        //                     for in a VoIP solution.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article17-img-b.png")} alt=""/>
        //                     <p>Voice over Internet Protocol (VoIP) has a lot of benefits for a business</p>
        //                 </div><br /><br />
        //                 <p><strong>What is VoIP?</strong></p>
        //                 <p>VoIP or Voice over Internet Protocol is a technology that <a href="https://www.yeastar.com/blog/what-is-voip/#what-is-voip">converts voice into digital signals</a>. In simpler
        //                     terms, it’s a call that is connected over the internet, and since it does not use analog phone lines, VoIP
        //                     is more cost-effective. Not only is it low-cost, but VoIP is also easy to install, reliable, and portable.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article17-img-c.png")} alt=""/>
        //                     <p>Consider necessary features before choosing a good VoIP</p>
        //                 </div><br /><br />
        //                 <p><strong>Important features of a good VoIP</strong></p>
        //                 <p>In looking for a <a href="https://www.yeastar.com/blog/what-is-voip/#considerations">good VoIP service</a>, consider these important things:</p>
        //                 <p><strong>Compatibility with existing equipment or new equipment.</strong> In cases of existing PBX equipment,
        //                     ensure that the selected VoIP solution allows a <a href="https://www.yeastar.com/solution/integration-legacy-pbx/">phased transition</a>.</p>
        //                 <p><strong>Cost of service.</strong> Always consider if the <a href="https://www.pcmag.com/news/7-tips-when-choosing-a-business-voip-service-for-your-smb">cost of the VoIP solution</a> is within the company’s budget and
        //                     determine how much is there that can be spent for a good VoIP that fits the company’s business
        //                     needs.</p>
        //                 <p><strong>Features.</strong> Decide which VoIP feature is important for the business. Providers like <a href="https://telavi.com.ph/products-services/#unified">Telavi Cloud</a> has
        //                     features that can cater to what is necessary for the business.</p>
        //                 <p><strong>The convenience of integration.</strong> Improve efficiency by always preparing for any technological
        //                     advancement. Ensure that the VoIP that the company will choose has the system to be able to
        //                     integrate and seamlessly work with other solutions for easier communication and collaboration.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article17-img-d.png")} alt=""/>
        //                     <p>List pros and cons before choosing a VoIP service</p>
        //                 </div><br /><br />
        //                 <p><strong>Choose a VoIP service that checks all</strong></p>
        //                 <p>In choosing a VoIP solution, it’s important to have a checklist of what the company’s necessities are.
        //                     It’s also essential to not only rely on hearsays. Doing proper research for the type of VoIP that fits
        //                     best with employee and customer needs for continued business success. List down the pros and
        //                     cons of each VoIP provider that are on the shortlist and choose the one that comes out the best.</p>
        //             </>
        //         )
        //         break;
        //     case "what-you-need-to-know-about-cloud-communications":
        //         setArticleContent(
        //             <>
        //                 <div>
        //                     <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                     {/* <span className="article-date">October 4, 2023</span> */}
        //                 </div>
        //                 <h1 className='title'>What you need to know about Cloud Communications</h1>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article18-img-a.png")} alt=""/>
        //                     <p>Cloud communication is necessary in a business</p>
        //                 </div>
        //                 <p>All across the globe, businesses are changing drastically. A lot of organizations are now preferring
        //                     cloud options instead of traditional legacy systems when it comes to communication, file sharing, and
        //                     other business-related activities.</p>
        //                 <p>Specifically, cloud communication is a method of communicating using <a href="https://www.uctoday.com/unified-communications/what-is-cloud-communications-your-getting-started-guide/">cloud-hosted technologies</a>.
        //                     This means that the connection used is coursed through the internet rather than the standard
        //                     telephone network. Cloud communications provide a lot of benefits to its users especially when it
        //                     comes to digital transformation.</p>
        //                 <div className="image-container">
        //                     <img src={require("../assets/images/article-img/article18-img-b.png")} alt=""/>
        //                     <p>Cloud communication and other cloud-based services provide convenience and more benefits</p>
        //                 </div><br /><br />
        //                 <p><strong>Benefits of Cloud Communications</strong></p>
        //                 <p>Cloud communication provides convenience in business communication and operations in general.
        //                     Some benefits include:</p>
        //                 <p><strong>Reliability</strong> - Cloud communications offer <a href="https://www.uctoday.com/unified-communications/what-is-cloud-communications-your-getting-started-guide/">better reliability</a> and service quality compared to on-premise equipment.</p>
        //                 <p><strong>Ease of Use</strong> - When paired with a good cloud communication service provider, there is a lot of rich selection of cloud-based features that can make everything simple and easy to use.</p>
        //                 <p><strong>Affordability</strong> - Cloud communication platforms are now flexible in terms of payment methods. TeLavi Cloud in particular offers a <a href="https://telavi.com.ph/products-services/#unified">subscription-based model</a> for a more affordable option.</p>
        //                 <p><strong>Scalability & Versatility</strong> - With cloud-based services or platforms, it’s easier to adjust the
        //                     communication strategy than with a PBX system. There is the freedom to easily upgrade and scale as
        //                     necessary with very little downtime.</p>
        //                 <p><strong>Overlooked Essential Cloud Communication Features</strong></p>
        //                 <p>Aside from the basic essential features such as video conferencing, team messaging, and file sharing,
        //                     there are cloud communication features that are just as important but often overlooked. One of which
        //                     is the 24/7 customer support feature. Although cloud-based services experience <a href="https://www.nextiva.com/products/cloud-pbx.html">less downtime or
        //                     outages</a>, it’s always important to have a customer support team that is always available, especially in
        //                     important cases.</p>
        //                 <p>Another overlooked feature is a <a href="https://blog.intermedia.com/essential-features-to-look-for-in-a-cloud-communications-platform/">cloud platform’s ability to integrate</a> other applications into it. This
        //                     feature is extremely important for a seamless experience when it comes to accessing information in
        //                     CRMs or other platforms as well.</p>
        //                 <p><strong>Shift to Cloud-based Services</strong></p>
        //                 <p>There are a lot of benefits to cloud-based services, but it all comes down to the company’s needs.
        //                     Does the company value convenient communication? Does the company have the budget to make
        //                     the shift?</p>
        //                 <p>Cloud communications provide a lot of benefits, and it is more of a question of “when” rather than a
        //                     question of “should I?” when it comes to making the shift.</p>
        //             </>
        //         )
        //         break;
        //     case "why-sip-trunks-will-help-call-center-efficiency":
        //             setArticleContent(
        //                 <>
        //                     <div>
        //                         <span className="article-tag tipsandadvice">Tips and Advice</span>
        //                         {/* <span className="article-date">October 4, 2023</span> */}
        //                     </div>
        //                     <h1 className='title'>Why SIP Trunks will Help Call Center Efficiency</h1>
        //                     <p>There is always a need for efficiency in a business, but none so more than call centers. With high
        //                         demands in service, increasing costs, and other service-related issues, it is important to increase
        //                         efficiency. <a href="https://www.twilio.com/blog/sip-trunking-contact-center">SIP Trunking helps provide the efficiency that a call center company needs</a> with its VoIP
        //                         call capabilities.</p>
        //                     <p>There is always a need for efficiency in a business, but none so more than call centers. <a href="https://www.philstar.com/business/2022/09/09/2208561/hybrid-work-home-setup-call-centers-it-firms-stays-until-further-notice">Companies
        //                         centered on IT business and call centers</a> agreed that remote or hybrid work is the business model
        //                         that works best for their employees and overall business.</p>
        //                     <div className="image-container">
        //                         <img src={require("../assets/images/article-img/article19-img-a.png")} alt=""/>
        //                         <p>Call Centers need to shift to SIP Trunking</p>
        //                     </div>
        //                     <p>And with high demands in service, increasing costs, and other service-related issues, it is important
        //                         to increase efficiency and lessen costs. <a href="https://www.twilio.com/blog/sip-trunking-contact-center">SIP Trunking helps provide the efficiency and cost-saving
        //                         that a call center company needs</a> with its voice over internet protocol (VoIP) call capabilities.</p>
        //                     <div className="image-container">
        //                         <img src={require("../assets/images/article-img/article19-img-b.png")} alt=""/>
        //                         <p>SIP Trunking and its importance and benefits to the call center business</p>
        //                     </div><br /><br />
        //                     <p><strong>What is SIP Trunking?</strong></p>
        //                     <p>SIP (Session Initiation Protocol) is a <a href="https://www.extrahop.com/resources/protocols/sip/">specific technology</a> that supports VoIP calls. It does not only
        //                         support voice calls but also video conferencing, instant messaging, and media distribution. Its main
        //                         advantage is that it provides a direct connection with private or local phone systems and the public
        //                         phone network, so there is no need for a legacy phone line connection. It also <a href="https://www.pldtenterprise.com/solutions/voice-communication/business-phone-lines/sip-trunks">integrates voice and
        //                         data on a single network</a> for network capacity optimization of the existing PBX or UC Platform.</p>
        //                     <p><strong>Benefits of SIP Trunks</strong></p>
        //                     <p>SIP in itself provides a whole set of <a href="https://www.pldtenterprise.com/solutions/voice-communication/business-phone-lines/sip-trunks">benefits</a>. It is efficient with its huge bandwidth allocation, allowing
        //                         as many voice calls as possible. It also has a streamlined service where it integrates voice and data to
        //                         a single network for increased utilization of its use. At the same time, SIP trunking is reliable, and its
        //                         connectivity and performance are as clear as a fixed line but for a lower cost.</p>
        //                     <div className="image-container">
        //                         <img src={require("../assets/images/article-img/article19-img-c.png")} alt=""/>
        //                         <p>Shift to the cloud for a better efficiency</p>
        //                     </div><br /><br />
        //                     <p><strong>SIP & Call Center Efficiency</strong></p>
        //                     <p>With remote or hybrid work increasingly being adopted by many call center companies in the country,
        //                         it is critical to digitally shift the business. According to <a href="https://www.avoxi.com/blog/how-sip-trunking-can-benefit-your-call-center/">a study</a>, call centers are adopting unified
        //                         communications strategies, with most of them migrating to the cloud. The most common and most reliable path to unified communications is SIP trunking. By moving to the cloud, the business is easily
        //                         modernized and has a more secured communication channel that ensures business continuity and
        //                         convenience.</p>
        //                     <p>SIP trunking and its benefits can only be utilized correctly if the service chosen is just as good.
        //                         <a href="https://telavi.com.ph/products-services/#unified">TeLavi Cloud</a> has a Call Center Solution that can support agents in different locations. With its
        //                         system, agents can activate phone lines using the latest technology in SIP trunk.</p>
        //                 </>
        //             )
        //             break;
        //     default:
        //         setArticleContent(
        //             <h1 className='text-center'>Sorry, we can't find the article you're looking for.</h1>
        //         )
        //         break;
        // }
        setLoading(false);
    }, [articleContent]);


    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <section className='space'></section>
        <section className='article'>
            <div className="content-wrapper" id='content-wrapper'>
                {articleContent}
            </div>
        </section>
        <section className='latestnews'>
                <div className="content-wrapper">
                    <h3 className='title news-header'>What's New with Telavi</h3>
                    <hr />
                    <div className="news-tiles">
                        <a className="news-item highlight" href='/media/city-of-san-fernando-takes-technological-leap-with-a-unified-hotline-number'>
                            <div className='news-image'>
                                <img src={require("../assets/images/article-img/article20-img-a.png")} alt="" />
                            </div>
                            <h3 className="news-title">City of San Fernando takes technological leap with a Unified Hotline Number</h3>
                            <h4 className="news-preview">The city government of San Fernando in the province of Pampanga has introduced a Voice Connectivity Integration or One Hotline Number – an effective means of communication for residents of the city. Gur Lavi Corp. (GLC), a prominent provider of voice communication solutions in the nation, supplied the technology for this groundbreaking project in San Fernando City, utilizing their flagship product, TeLavi, an innovative all-in-one communication platform recognized as a trailblazer in the country.</h4>
                        </a>
                        <a className="news-item" href='/media/laguna-to-launch-centralized-hotline-number-for-easy-access-to-service-and-faster-government-response'>
                            <div className='news-image'>
                                <img src={require("../assets/images/article-img/article21-img-a.png")} alt="" />
                            </div>
                            <h3 className="news-title">Laguna to launch centralized hotline number for easy access to service and faster government response</h3>
                        </a>
                        <a className="news-item" href='/media/6-advantages-of-cloud-telephony'>
                            <div className='news-image'>
                                <img src={require("../assets/images/article-img/article12-img-a.png")} alt="" />
                            </div>
                            <h3 className="news-title">6 Advantages of Cloud Telephony</h3>
                        </a>
                        <a className="news-item" href='/media/10-reasons-to-shift-to-cloud-office'>
                            <div className='news-image'>
                                <img src={require("../assets/images/article-img/article6-img-a.png")} alt="" />
                            </div>
                            <h3 className="news-title">10 Reasons to Shift to Cloud Office</h3>
                        </a>
                        <a className="news-item" href='/media/how-to-collaborate-productively-in-any-work-set-up'>
                            <div className='news-image'>
                                <img src={require("../assets/images/article-img/article8-img-a.png")} alt="" />
                            </div>
                            <h3 className="news-title">How to Collaborate Productively in Any Work Set-up</h3>
                        </a>
                    </div>
                    <a href="/media" className='see-more'>SEE MORE NEWS AND ARTICLES</a>
                </div>
            </section>
        </>
        </>
    );
}