import React, {useRef, useState, useEffect} from 'react';
import { Fade } from "react-awesome-reveal";
import "../assets/styles/Contact.scss";
import tile from '../assets/images/tile-contact.png'
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet-async';
import "react-datepicker/dist/react-datepicker.css";
import referralImage from "../assets/images/partnerProgram-newBanner.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PartnerProgramSignUpForm(props) {

    const [loading, setLoading] = useState(true);
    const [overlayLoading, setOverlayLoading] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        var els = document.querySelectorAll("input");
        els.forEach(item => {item.onkeydown = ()=>{item.classList.remove("error")}});
        setLoading(false);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        var button = document.querySelector("input[type='submit']");
        setOverlayLoading(true);
        button.setAttribute("disabled", "disabled");
        
        const form = e.target.closest("form");
        const inputs = form.querySelectorAll("input")
        // const emailRegex = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const emailRegex = new RegExp (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const phoneRegex = new RegExp (/^[0-9]{10,11}$/);
        // const phoneRegex = new RegExp (/^09\d{9}$/);
        // const phoneRegex63 = new RegExp (/^639\d{9}$/);
        // const telephoneRegex = new RegExp (/^\d{10}$/);
        inputs.forEach((item)=>item.classList.remove("error"));

        const formData = new FormData(form);
        var validated = true;
        var errMessage = ""

        var data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        // VALIDATION
        
        // CHECK FOR T&C
        if(document.querySelector("input[name='acceptTNC']:checked") == null){
            validated=false;
            addError("acceptTNC");
            errMessage="Please agree to Terms and Condition";
        }

        // CHECK CONTACT
        if(!phoneRegex.test(data["officeNo"])/*  && !phoneRegex63.test(data["officeNo"]) && !telephoneRegex.test(data["officeNo"]) */){
            validated=false;
            addError("officeNo");
            errMessage="Invalid Number";
        }
        if(!phoneRegex.test(data["mobileNo"])/*  && !phoneRegex63.test(data["mobileNo"]) && !telephoneRegex.test(data["mobileNo"]) */){
            validated=false;
            addError("mobileNo");
            errMessage="Invalid Number";
        }

        // CHECK EMAIL
        if(!emailRegex.test(data["email"])){
            validated=false;
            addError("email");
            errMessage="Invalid Email";
        }
        


        // CHECK FOR EMPTY
        formData.forEach((value, key) => {
            if(value=="" || value=="null"){
                validated=false;
                errMessage="Please complete the form";
                addError(key);
            }
        });
        if(validated){
            var json = JSON.stringify(data);

            const response = await fetch(
                "https://2qxi85kv34.execute-api.ap-southeast-1.amazonaws.com/prod/partner-program-signup", {
                method: 'POST',    
                headers: {
                    'Content-Type': 'application/json'
                },        
                body: json
            });
            const res = await response.json();
    
            console.log(res);
            
            if(res.status == "success"){
                navigate("/case-success")
                // navigate("/case-success?referralCode="+res.referralCode);
            }
            else if(res.status == "error"){
                setTimeout(() => {
                    toast.info('You are already registered.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
            else{
                setTimeout(() => {
                    toast.error('Uh oh! Something went wrong, please try again.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
        
        }else{
            toast.error(errMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            setOverlayLoading(false);
            button.removeAttribute("disabled");
        }
    }

    function addError(key) { 
        var el = document.querySelector("input[name='"+key+"']");
        if(!el){var el = document.querySelector("select[name='"+key+"']");}
        el.classList.add("error")
    }

    const [isOthersShown, setIsOthersShown] = useState(false);
    function handleSelectChange(e){
        e.target.classList.remove("error");
        e.target.value == "Others" ? setIsOthersShown(true) : setIsOthersShown(false);
    }

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <Helmet>
                <title>Telavi - Partner Program: Deal Form</title>
        </Helmet>
        {overlayLoading ? 
            <div className="overlay-loader">
                <Loader/>
            </div>
            :""
        }
        <section className='referral-page midbanner midbanner-5050 p-0'>
            <div className="content-wrapper">
                <div className="left">
                    <div className="form-header">
                        <img src={tile} className='tile tile-second'/>
                        <div>
                            <h1>Partner Program: <br />Sign-Up Form</h1>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="midbanner-img">
                        <img src={referralImage} className=''/>
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="left pt-0">
                    <form className='pt-0'>
                        <input type="text" placeholder='Company Name' name='companyName'/>
                        <input type="text" placeholder='Address' name='address'/>
                        <select name="type" onChange={(e)=>{handleSelectChange(e)}}>
                            <option value="null" selected>Type</option>
                            <option value="System Integrator">System Integrator</option>
                            <option value="Telco/ISP">Telco/ISP</option>
                            <option value="Others">Others</option>
                        </select>
                        {isOthersShown ?
                            <input type="text" placeholder='Please specify others...' name='other'/> : ""
                        }
                        <input type="number" placeholder='Office Number (09XX-YYY-ZZZZ) or (XX-YYYY-ZZZZ)' name='officeNo' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                        <input type="number" placeholder='Mobile Number (09XX-YYY-ZZZZ) or (XX-YYYY-ZZZZ)' name='mobileNo' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                        <input type="text" placeholder='First Name' name='firstName'/>
                        <input type="text" placeholder='Last Name' name='lastName'/>
                        <input type="email" placeholder='Email Address' name='email'/>
                        <input type="text" placeholder='Contact Person in GLC/Telavi' name='contactPersonInGLC'/>
                        <div className="check-box">
                            <label className="check-container">
                                <input type="checkbox" name='acceptTNC'/>
                                <span className="checkmark"></span>
                                <span>I have read and agree to the &nbsp;<a href="/referral-agreement" target='_blank'>Terms and Conditions</a></span>
                            </label>
                        </div>
                        <input type="submit" value="Submit"  className='btn btn-black' onClick={(e)=>{handleSubmit(e)}}/>
                    </form>
                </div>
                <div className="right">
                    <div className="midbanner-img">
                        <img src={require("../assets/images/partnerProgramBanner-tile.png")} className='partnerProgramTile' />
                        <div className="midbanner-img-text">
                            <h1>
                                Ready to Partner Up?<br />Sign-Up Now!
                            </h1>
                            <ul>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Special discounts from MSRP                       
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Rebate for achieved targets
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Perks for Sales Representatives
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Market Development Fund for achieved targets
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Get Certified with our Comprehensive Training - Free for Partners
                                    </span>
                                </li>
                                <li>
                                    <img src={require("../assets/images/check.png")} alt="" /> 
                                    <span>
                                        Free Telavi Product Demo
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ToastContainer/>
        </>
        </>
    );
}