import React, {useRef, useState, useEffect} from 'react';
import Loader from '../components/Loader';
import { Fade, Zoom } from "react-awesome-reveal";

import { useAppContext } from '../libs/contextLib';

import "../assets/styles/Privacy.scss";

export default function ReferralAgreement() {
    const {isPageLight, setIsPageLight} = useAppContext();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setIsPageLight(false);
        // const logoBlue = document.querySelector("#header-logo.blue");
        // const logoLight = document.querySelector("#header-logo.light");

        // logoBlue.style.display="block";
        // logoLight.style.display="none";

        // console.log(logoBlue, logoLight)
        // logo.setAttribute("src", "https://telavi-assets.s3.ap-southeast-1.amazonaws.com/telavi-blue.png")

        setLoading(false);
    }, []);

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <section className='privacy'>
            <article>
                <h1>REFERRAL AGREEMENT</h1>
                <div className="contact-outer">
                    <p>&nbsp; <br/>
                        This Referral Agreement (“<b>Agreement</b>”) entered into between You (“<b>Referrer</b>”) and <b>GURL LAVI CORP</b>, a company incorporated under the laws of the Philippines and with office address at 220 Wilson St. San Juan City Metro Manila (“<b>Company</b>”) and takes effect as set forth in below. <br />
                        Company and Referrer shall hereinafter individually be referred to as <b>Party</b> and collectively as <b>Parties</b>.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <b>WHEREAS</b>, Company develops, markets, and sells Telavi Cloud solutions (the “Services”);
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <b>WHEREAS</b>, Referrer has business contacts who could benefit from the Services; 
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <b>NOW, THEREFORE</b>, in consideration of the mutual promises hereinafter set forth, Company and Referrer do hereby agree as follows:
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>1. Operating Principles </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.1</span>
                            <span>
                                Company wishes to engage the Referrer on a non-exclusive basis whereby the Referrer shall recommend the products and/or services offered by the Company to prospective customers (“<b>Prospects</b>”).
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.2</span>
                            <span>
                                Referrer must sign up for the program by registering through the Telavi website or other approved channels and accepting the program's terms and conditions.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.3</span>
                            <span>
                                Referrer is required to provide valid identification details to complete the registration process to be eligible for the program. Referrer can start referring his/her network once he/she receives the unique referral code from the Company.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.4</span>
                            <span>
                                Referrer can refer a Prospect by completing and submitting the end-user endorsement form to the Company. As an acknowledgment, an email notification will be sent by Company to the Referrer. 
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.5</span>
                            <span>
                                Referral would be deemed successful only when there is a business transaction resulting from the lead provided by the Referrer to the Company, which results in the execution of a definitive agreement between the Company and the end-user (“Contract”). 
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.6</span>
                            <span>
                                Referrer is acting as a finder only and shall have no authority to enter into any agreements, obligations, or commitments on Company's behalf, or to negotiate the terms of any Prospect’s agreement with Company. 
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.7</span>
                            <span>
                                Referrer acknowledges that Company may enter into referral or other similar arrangements with other parties and that Referrer shall have no rights under such agreements or to any fees for customers referred to Company by others or identified by Company itself.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.8</span>
                            <span>
                                Employees of Company and their immediate family members up to the third degree of consanguinity are ineligible to participate in the program. If an employee is found to have breached this rule, they will be subject to disciplinary measures as determined by HR, which may encompass sanctions up to and including termination of employment.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>1.9</span>
                            <span>
                                Referrer shall be entitled to a referral fee upon the successful completion of a transaction between the Company and the Prospect, provided that the Referrer was the first to submit the Prospect's information in writing to the Company, and the Company had not previously been in contact with the Prospect or received the Prospect's information from another source. If the Referrer is not the first to register the Prospect, the Company shall promptly notify the Referrer.
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>2. Commissions </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>2.1</span>
                            <span>
                                The Referrer shall be entitled to receive a commission equivalent to one month's worth of the 	monthly recurring cost (MRC) of the total contract value of Telavi Cloud actually subscribed to by the Prospect referred by Referrer.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>2.2</span>
                            <span>
                                The Referrer shall receive the referral commission from the Company only upon the successful collection of the Prospect’s first monthly payment. 
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>2.3</span>
                            <span>
                                The referral commission is subject to a 5% withholding tax if the gross income does not exceed 	₱3 million, and a 10% withholding tax if the gross income exceeds ₱3 million.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>2.4</span>
                            <span>
                                The referral commission shall be issued after five (5) working days following the submission of the Request for Payment (RFP) by the Referrer, subject to 2.2 above. Payment may be made via bank transfer or cheque, as determined by the Company.
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>3. Term, Renewal and Termination </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>3.1</span>
                            <span>
                                The referral program shall be in effect for a period of one (1) year, commencing retroactively on April 15, 2024, and ending on April 15, 2025.                            
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>3.2</span>
                            <span>
                                This Agreement shall remain in force until April 15, 2025, unless terminated earlier by either Party in accordance with the provisions set forth herein, whichever occurs first. This Agreement may be extended upon mutual written consent of the Parties.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>3.3</span>
                            <span>
                                Either Party may terminate this Agreement by providing written notice to the other Party with a minimum notice period of thirty (30) days. In addition, either Party may terminate this Agreement in the event of a breach of one or more provisions of this Agreement by the other Party, provided that such breach remains unremedied for a period of thirty (30) days following written notice to the breaching Party specifying the breach and demanding its cure.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>3.4</span>
                            <span>
                                On the termination or expiration of this Agreement, each Party will return all property and materials belonging to the other Party and undertakes not to use the proprietary or confidential information of the other Party in any manner whatsoever, except with the prior written consent of the other Party. Any termination is without prejudice to any other rights of either Party arising under this Agreement prior to such termination or expiration.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>3.5</span>
                            <span>
                                Each Party agrees and acknowledges that termination of this Agreement shall not affect any rights and obligations of each Party that may have accrued prior to such termination. 
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>4. Confidentiality </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>4.1</span>
                            <span>
                                During the course of the performance of obligations under this Agreement, each Party (“Disclosing Party”) may disclose to the other Party including its authorized representatives with a need to know (“Receiving Party”) business, technical or financial information relating to the business to better facilitate and avail the services or information identified or marked as “Confidential” or under any similar legend indicating the confidentiality (hereinafter referred to as “Confidential Information” of the Disclosing Party). The terms of this Agreement and existence of this business relationship shall also be considered Confidential Information.  
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>4.2</span>
                            <span>
                                The Receiving Party agrees: (a) to take reasonable precautions at its own cost to protect such Confidential Information; (b) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third party any such Confidential Information, except as it may be authorized by the Disclosing Party in writing; (c) to inform its representatives about the confidential nature of the information, and assure their compliance with the obligations set out in this Agreement; and (d) to be responsible for any act or omissions by such representatives under this Agreement. 
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>4.3</span>
                            <span>
                                The obligations as mentioned above shall not apply, however, to any information which is: (a) previously known to the Receiving Party at the time of disclosure; or (b) independently developed by the Receiving Party and not derived from the Confidential Information supplied by the Disclosing Party or the participation of individuals who have had access to Confidential Information; or (c) disclosed to the Receiving Party by a third party without an obligation of confidentiality; or (d) in or subsequently comes into the public domain (other than as a result of a breach of this Agreement); or (e) required to be disclosed by the Receiving Party by law, regulation, court order or other legal process. 
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>5. Intellectual Property </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>5.1</span>
                            <span>
                                Referrer acknowledges and agrees that, as between Referrer and Company, the Company owns and shall retain ownership of all right, title, and interest in and to (i) the Telavi Cloud Platform, (ii) all improvements, enhancements, or modifications to the Telavi Cloud Platform, and (iii) all intellectual property rights related to any of the foregoing, including but not limited to all Company marks, logos, signs, symbols, and trademarks used by Company within its scope of business activity.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>5.2</span>
                            <span>
                                By entering into this Agreement, the Referrer undertakes: (a) except for the purpose of this Agreement, not to use Company’s intellectual property without the prior express written consent of the Company; (b) to ensure the confidentiality of such intellectual property of the Company within its organization. 
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>6. Representations and Warranties  </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>6.1</span>
                            <span>
                                Each Party represents and warrants that it possesses all necessary corporate power and authority to enter into this Agreement and fulfill its obligations hereunder; that, to the best of its knowledge, the execution and delivery of this Agreement do not conflict with any applicable laws, regulations, or existing contractual obligations; and that it will comply with all applicable laws, orders, rules, ordinances, and regulations (collectively, 'Laws') in connection with its performance under this Agreement.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>6.2</span>
                            <span>
                                Referrer represents and warrants that there is no legal impediment for him/her to make referrals under this Agreement and the Company shall be held free and harmless from any consequence of any referral made by Referrer in violation of this representation and warranty, including but not limited to loss of business, reputational damage, legal claims or financial fees that may be imposed on Referrer by any entity that may be adversely affected by any referral made by Referrer.  Referrer acknowledges and agrees that he/she is solely responsible for disclosing the referral commission arrangement to whatever entity from which Referrer is legally obligated to obtain permission before he/she could enter into this Agreement and the Company shall not be held liable for Referee’s failure to inform and/or obtain prior approval.   
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>7. Indemnification  </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>7.1</span>
                            <span>
                                Each Party agrees to defend, indemnify and hold the other Party harmless against and from any claims, losses, damages, and expenses (including reasonable court or arbitration costs, attorney's fees, expert fees, and costs of investigation) relating to third party claims to the extent arising from: (a) breach of confidentiality terms stated herein; or (b)  infringement or violation of any copyright, patent, trademark or other intellectual property right of any third party unless the claim arises out of or is a result of the other party's acts or omissions causing said infringement or violation; or (c) breach of representations and warranties. 
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>7.2</span>
                            <span>
                                To the extent indemnity provided in clause 7.1 applies, the Party responsible for the indemnity shall pay all damages and costs finally awarded against the other Party or amount as settled by the Party in consultation with the other Party, to the extent attributable to such Party’s indemnity, provided that the other Party: (a) promptly gives notice of any such claim or suit by a third party and furnishes a copy of each communication, notice or other action relating to such claim or suit; (b) permits the other Party to assume sole authority to conduct the trial or settlement of such claim or suit or any negotiations related thereto at its own expense; and (c) provides information and assistance reasonably requested in connection with such claim.
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>8. Limitation of Liability:</strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span></span>
                            <span>
                                IN NO EVENT SHALL EITHER PARTY (INCLUDING ITS SUPPLIERS, OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES) BE HELD LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, STRICT LIABILITY OR OTHER THEORY: (A) FOR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND EITHER PARTY’S REASONABLE CONTROL; OR (D) FOR AN AGGREGATE AMOUNT THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY THE COMPANY TO THE REFERRER IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE CLAIM WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>9. Notices </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span></span>
                            <span>
                                All notices, requests, demands, and other communications given or made in accordance with 	the provisions of this Agreement shall be in writing by letter or email with a signed PDF 	attachment. 
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>10. Force Majeure </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>5.1</span>
                            <span>
                                Neither Party shall be responsible for failure or delay of performance, if caused by: act of war, hostility, sabotage, act of God, strikes, pandemic, electrical, internet, telecommunication outage, government restrictions, or other event outside the reasonable control of the Parties. If such force majeure event continues for more than 90 days, either Party may terminate this Agreement by issuing a written notice
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>11. Governing Law & Dispute Resolution  </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>11.1</span>
                            <span>
                                This Agreement shall be governed by and construed in accordance with the laws of the Philippines, without regard to its conflict of laws principles. Any dispute, claim, or controversy arising out of or relating to this Agreement shall first be resolved through good faith negotiation between the Parties, and if unresolved within thirty (30) days, either Party may pursue binding arbitration in accordance with the rules in San Juan City with the decision being final and binding. Each Party shall bear its own costs and legal fees unless otherwise awarded by the arbitrator, and each Party shall continue to perform its obligations during the dispute resolution process, unless otherwise agreed. 		
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>12. Miscellaneous </strong>
                    </p>
                    <div className='pl-5'>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.1</span>
                            <span>
                                <b>Amendment:</b> This Agreement may only be modified by a written instrument executed by an authorized representative of each Party.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.2</span>
                            <span>
                                <b>Entire Agreement:</b> This Agreement sets forth the entire agreement between the Parties on the subject matter and supersedes all previous or contemporaneous agreements, oral or written between the Parties.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.3</span>
                            <span>
                                <b>Independent status:</b> No agency, partnership, joint venture, or employment is created by this Agreement and Referrer does not have authority to bind the Company in any respect, except in accordance with the terms of the Agreement.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.4</span>
                            <span>
                                <b>Non-Solicit:</b> Neither Party will either offer to employ nor employ, directly or otherwise, any of the other Party’s employees, associated for the purpose of, or with the assignment, during the term of this Agreement and for a period of two (2) years thereafter.  
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.5</span>
                            <span>
                                <b>Publicity:</b> Neither Party will use the other party's name, logo, or trademarks, or issue any press release or public announcement regarding this Agreement, without the other party's written consent.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.6</span>
                            <span>
                                <b>Severability:</b> If any provision of this Agreement shall be held or made invalid by a court decision, statute, rule or otherwise, the remainder of this Agreement shall remain unaffected and valid.
                            </span>
                        </p>
                        <p style={{display:"grid", gridTemplateColumns:"5% 1fr", padding:"10px 0"}}>
                            <span>12.7</span>
                            <span>
                                <b>Waiver:</b> No waiver will be effective unless it is in writing and signed by the authorized representative of the Party condoning the waiver.
                            </span>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p style={{textIndent: "5%"}}>
                        BY CLICKING “I AGREE” YOU ARE REPRESENTING THAT (1) YOU HAVE SUBMITTED 	TRUE AND COMPLETE INFORMATION IN THE APPLICATION REGISTRATION AND (2) 	YOU ARE ENTERING INTO THIS AGREEMENT ON YOUR BEHALF OR THAT YOU 	HAVE AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF YOUR 	COMPANY OR OTHER LEGAL ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY OR 	DO NOT WISH TO ENTER INTO THIS AGREEMENT ON YOUR BEHALF, OR IF YOU DO 	NOT AGREE WITH THE TERMS AND CONDITIONS CONTAINED HEREIN, DO NOT 	CLICK “I AGREE” AND YOU WILL NOT BE ELIGIBLE FOR PARTICIPATION IN THE 	REFERRAL PROGRAM. 
                    </p>
                </div>
            </article>
        </section>
        </>
        </>
    );
}