import React, {useRef, useState, useEffect} from 'react';
import { useParams, useLocation } from 'react-router-dom'
import Loader from '../components/Loader';

import "../assets/styles/PageNotFound.scss";

import envelope from "../assets/images/envelope.png";
import tile from '../assets/images/tile-contact.png'

export default function ThankYou() {
    const [loading, setLoading] = useState(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    useEffect(() => {
        setLoading(false);        
        let header = document.getElementsByTagName('header');
            header[0].style.display = 'none';
        setTimeout(() => {
            let footer = document.getElementsByTagName('footer');
                footer[0].style.display = 'none';
            let contact = document.getElementsByClassName('contact-us');
                contact[0].style.display = 'none !important';
                contact[0].classList.add("hidden");
        }, 1501);
    });

    function clickHandler(event) {
        window.top.location.href="https://telavi.com.ph/";
    }

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <section className='thankyou'>
            <img src={tile} className='tile'/>
            <img src={tile} className='tile2'/>
            <img src={envelope} alt="Envelope" className='thankyou-image' />
            <h1>
                Thank you
            </h1>
            <p>Your submission has been received.</p>
            <p>We will be in touch and contact you soon.</p>
            {urlParams.has("referralCode")?
                <>
                    <br /><br />
                    <p>Your referral code is:</p>
                    <h1 className='referralCode'>{urlParams.get("referralCode")}</h1>
                    <p>Please take note of your referral code.</p>
                </>
                : ""
            }

            <a href='#' onClick={clickHandler} className="btn btn-black">Back Home</a>
        </section>
        </>
        </>
    );
}