import React, {useRef, useState, useEffect} from 'react';
import { Fade } from "react-awesome-reveal";
import "../assets/styles/Contact.scss";
import tile from '../assets/images/tile-contact.png'
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet-async';
import "react-datepicker/dist/react-datepicker.css";
import referralImage from "../assets/images/referee-img.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

export default function RefereeForm(props) {

    const [loading, setLoading] = useState(true);
    const [overlayLoading, setOverlayLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        var els = document.querySelectorAll("input");
        els.forEach(item => {item.onkeydown = ()=>{item.classList.remove("error")}});
        setLoading(false);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        var button = document.querySelector("input[type='submit']");
        setOverlayLoading(true);
        button.setAttribute("disabled", "disabled");
        
        
        const form = e.target.closest("form");
        const inputs = form.querySelectorAll("input")
        // const emailRegex = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const emailRegex = new RegExp (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const phoneRegex = new RegExp (/^[0-9]{10,11}$/);
        // const phoneRegex = new RegExp (/^09\d{9}$/);
        // const phoneRegex63 = new RegExp (/^639\d{9}$/);
        // const telephoneRegex = new RegExp (/^\d{10}$/);
        inputs.forEach((item)=>item.classList.remove("error"));

        const formData = new FormData(form);
        var validated = true;
        var errMessage = ""

        var data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        // VALIDATION
        // CHECK FOR T&C
        if(document.querySelector("input[name='acceptTNC']:checked") == null){
            validated=false;
            addError("acceptTNC");
            errMessage="Please agree to Terms and Condition";
        }

        // CHECK CONTACT
        if(!phoneRegex.test(data["officeNo"]) /* && !phoneRegex63.test(data["officeNo"])&& !telephoneRegex.test(data["officeNo"]) */){
            validated=false;
            addError("officeNo");
            errMessage="Invalid Office Number";
        }
        if(!phoneRegex.test(data["mobileNo"]) /* && !phoneRegex63.test(data["mobileNo"]) && !telephoneRegex.test(data["mobileNo"]) */){
            validated=false;
            addError("mobileNo");
            errMessage="Invalid Mobile Number";
        }

        // CHECK EMAIL
        if(!emailRegex.test(data["email"])){
            validated=false;
            addError("email");
            errMessage="Invalid Email";
        }
        
        // CHECK FOR EMPTY
        formData.forEach((value, key) => {
            if(key != "middleName"){
                if(value=="" || value=="null"){
                    validated=false;
                    errMessage="Please complete the form";
                    addError(key)
                }
            }
        });
        if(validated){
            var json = JSON.stringify(data);

            const response = await fetch(
                "https://2qxi85kv34.execute-api.ap-southeast-1.amazonaws.com/prod/referee", {
                method: 'POST',    
                headers: {
                    'Content-Type': 'application/json'
                },        
                body: json
            });
            const res = await response.json();
            
            setOverlayLoading(false);
            button.removeAttribute("disabled");
    
            if(res.status == "success"){
                navigate("/case-success")
            }
            else if(res.status == "error"){
                if(res.message == "You are already registered"){
                    setTimeout(() => {
                        toast.info('You are already registered.', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            transition: Bounce,
                        });
                        setOverlayLoading(false);
                    }, 500);
                }else if(res.message == "This referral code does not exist."){
                    setTimeout(() => {
                        document.querySelector('input[name="referralCode"').classList.add("error");
                        toast.error('This referral code does not exist.', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            transition: Bounce,
                        });
                        setOverlayLoading(false);
                        button.removeAttribute("disabled");
                    }, 500);
                }
            }
            else{
                setTimeout(() => {
                    toast.error('Uh oh! Something went wrong, please try again.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
        
        }else{
            toast.error(errMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            setOverlayLoading(false);
            button.removeAttribute("disabled");
        }
    }

    function addError(key) { 
        var el = document.querySelector("input[name='"+key+"']");
        el.classList.add("error")
    }

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <Helmet>
                <title>Telavi - Referee Form</title>
        </Helmet>
        {overlayLoading ? 
            <div className="overlay-loader">
                <Loader/>
            </div>
            :""
        }
        <section className='referral-page midbanner midbanner-5050 p-0'>
            <div className="content-wrapper">
                <div className="left">
                    <div className="form-header">
                        <img src={tile} className='tile tile-second'/>
                        <div>
                            <h1>Telavi End User Form</h1>
                            <h3>Begin your Telavi journey and discover the world of digitization now!</h3>
                        </div>
                    </div>
                    <form>
                        <input type="text" placeholder='Company Name' name='companyName'/>
                        <input type="text" placeholder='First Name' name='firstName'/>
                        <input type="text" placeholder='Last Name' name='lastName'/>
                        <input type="text" placeholder='Job Title' name='jobTitle'/>
                        <select name="contactPosition">
                            <option value="null" selected disabled>Contact Position</option>
                            <option value='Owner'>Owner</option>
                            <option value='Pre-Sales'>Pre-Sales</option>
                            <option value='Sales'>Sales</option>
                            <option value='Purchasing'>Purchasing</option>
                            <option value='Accounting'>Accounting</option>
                            <option value='Marketing'>Marketing</option>
                            <option value='Technical'>Technical</option>
                            <option value='Others'>Others</option>
                            <option value='IT'>IT</option>
                            <option value='Management'>Management</option>
                            <option value='Human Resource'>Human Resource</option>
                            <option value='Business Development'>Business Development</option>
                            <option value='Audit'>Audit</option>

                        </select>
                        <input type="email" placeholder='Email Address' name='email'/>
                        <input type="number" placeholder='Office Number (09XX-YYY-ZZZZ) or (XX-YYYY-ZZZZ)' name='officeNo' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                        <input type="number" placeholder='Mobile Number (09XX-YYY-ZZZZ) or (XX-YYYY-ZZZZ)' name='mobileNo' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                        <select name="industry">
                            <option value="null" selected disabled>Industry</option>
                            <option value='Banking and Finance'>Banking and Finance</option>
                            <option value='ΒΡΟ'>ΒΡΟ</option>
                            <option value='Manufacturing'>Manufacturing</option>
                            <option value='POGO'>POGO</option>
                            <option value='IT and System Integrator'>IT and System Integrator</option>
                            <option value='Retail & Micorbiz'>Retail & Micorbiz</option>
                            <option value='Health Care'>Health Care</option>
                            <option value='Education'>Education</option>
                            <option value='Telecommunications'>Telecommunications</option>
                            <option value='Media'>Media</option>
                            <option value='Real Estate & Construction'>Real Estate & Construction</option>
                            <option value='Hospitality & Tourism'>Hospitality & Tourism</option>
                            <option value='Transportation & Logistics'>Transportation & Logistics</option>
                            <option value='Airlines'>Airlines</option>
                            <option value='Religious Organization'>Religious Organization</option>
                            <option value='National Government Unit'>National Government Unit</option>
                            <option value='Local Government Unit'>Local Government Unit</option>

                        </select>
                        <input type="text" placeholder='Contact Person in GLC' name='contactPersonInGLC'/>
                        <input type="text" placeholder='Company Address' name='companyAddress'/>
                        <input type="text" placeholder='Referral Code' name='referralCode'/>
                        <div className="check-box">
                            <label className="check-container">
                                <input type="checkbox" name='acceptTNC'/>
                                <span className="checkmark"></span>
                                {/* <span>I have read and agree to the &nbsp;<a onClick={() => {handleDownload}}>Terms and Conditions</a></span> */}

                                <span>I have read and agree to the &nbsp;<a href="https://telavi-assets.s3.ap-southeast-1.amazonaws.com/Telavi+Referral+Agreement.pdf" download="Terms and Condition.pdf">Terms and Conditions</a></span>
                            </label>
                        </div>
                        <input type="submit" value="Submit"  className='btn btn-black' onClick={(e)=>{handleSubmit(e)}}/>
                    </form>
                </div>
                <div className="right">
                    <div className="midbanner-img">
                        <img src={referralImage} className=''/>
                    </div>
                </div>
            </div>
        </section>
        <ToastContainer/>
        </>
        </>
    );
}