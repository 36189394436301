import React, {useRef, useState, useEffect} from 'react';
import Loader from '../components/Loader';
import { Fade, Zoom } from "react-awesome-reveal";
import { useAppContext } from '../libs/contextLib';
import { useParams } from 'react-router';
import "../assets/styles/NewsAndArticles.scss";

import banner1 from "../assets/images/NewsAndArticle-banner.png"
import bannertile from "../assets/images/inner-page-tile.png"

export default function NewsAndArticles() {
    const {isPageLight, setIsPageLight} = useAppContext();
    const [loading, setLoading] = useState(true);
    const params= useParams();
    const [articles, setArticles] = useState([]);

    let articlesAPI = 'https://api.telavidev.gurlavi.com.ph/cmsadmin/articles/getall';

    const getAllArticles = async () => {
        await fetch(articlesAPI)
        .then(response => response.json())
        .then(data => setArticles(data));

        console.log(articles);
    }

    useEffect(() => {
        // setIsPageLight(false);
        console.log(params.slug);
        getAllArticles();
        setLoading(false);
    }, []);


    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <section className='banner inner-page'>
            <div className="content-wrapper">
                <div className="left">
                    <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                        <img src={bannertile} className='inner-banner-tile'/>
                    </Fade>
                    <div className="brand">
                        <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                            <h1 className="title">
                                News and Articles
                            </h1>
                        </Fade>
                    </div>
                </div>
                <div className="right imgbanner-wrapper">
                    <Fade triggerOnce duration={1500}>
                        <img src={banner1} className='imgbanner'/>
                    </Fade>
                </div>
            </div>
        </section>
        <section className="news-and-articles">
            <div className='content-wrapper'>
                {articles.map((list,index) => (       
                    <a key={index} className="naa-item" href={'/media/' + list.slug}>
                        <div>
                            <span className={"article-tag " + list.type}>{/*Service Spotlight*/}</span>
                            {/* <span className="article-date">October 4, 2023</span> */}
                        </div>
                        <div className='naa-image'>
                            <img src={"https://cdn.telavidev.gurlavi.com.ph/articles/" + list.thumbnail} alt={list.title} />
                            <img src={"https://cdn.telavidev.gurlavi.com.ph/articles/" + list.thumbnail} alt={list.title} className="backthumb"/>
                        </div>
                        <h2 className="naa-title">{list.title}</h2>
                        <p className="naa-preview">{list.details}</p>
                    </a>
                ))}
            </div>
            {/* <div className="content-wrapper">
                <a className="naa-item" href='/media/top-6-best-features-in-a-unified-communications-platform'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article13-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Top 6 Best Features in a Unified Communications Platform</h2>
                    <p className="naa-preview">Look out for the best features in a Unified Communications (UC)Service or Platform In a fast-paced society, there is always an increasing demand for software or applications for better productivity. As remote and hybrid work continues, it’s important to use platforms that are all in one unified place. Unified communications (UC) platforms or products (e.g. equipment, software, and services) provide and integrate communications and collaboration tools such as IP phones, web and video conferencing, voicemails, personal & team messaging, and more. But what should be taken into account when looking for a UC service or platform?</p>
                </a>
                <a className="naa-item"  href='/media/6-advantages-of-cloud-telephony'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article12-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">6 Advantages of Cloud Telephony</h2>
                    <p className="naa-preview">Cloud telephony has a lot of benefits for companies and individuals. Cloud telephony uses VoIP to connect phone calls using the internet. It is an advanced telecommunications service that uses the cloud, creating flexibility to do business remotely. With this, there are a lot of advantages that companies and even individuals will benefit from.</p>
                </a>
                <a className="naa-item"  href='/media/telavi-cloud-a-necessary-business-continuity-plan-component'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article25-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Telavi Cloud: A necessary business continuity plan component</h2>
                    <p className="naa-preview">The business landscape in these uncertain times is quite difficult to navigate. Many institutions face various challenges brought about by
                            unexpected emergency situations like Covid-19. They must adapt to the ever-changing climate in order to stay afloat in the new normal. To be able to maintain operations smoothly during times of crises, an organization, whatever size, revenue or in whatever industry, must have a
                            strong business continuity plan in place, otherwise the business will suffer the consequences.</p>
                </a>
                <a className="naa-item"  href='/media/telavi-cloud-brings-office-productivity-to-the-next-level'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article24-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Telavi Cloud Brings Office Productivity to the Next Level</h2>
                    <p className="naa-preview">Manila, Philippines - The business landscape has been evolving rapidly, driven by the worldwide pandemic. These precarious times call for
                            increased flexibility, mobility, with the goal of growing productivity. Enterprises must encourage their workforce to adapt quickly or be left behind.
                            One of these innovative business shifts is the remote work setup.</p>
                </a>
                <a className="naa-item"  href='/media/unified-solutions-to-save-money-in-the-new-normal'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article26-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Unified Solutions To Save Money In The New Normal</h2>
                    <p className="naa-preview">The pandemic has changed so many things including the way companies operate. Edric Mendoza, self-titled change-maker, entrepreneur and former news anchor of ANC’s “On the Money”, shares the top financial problems
                            companies faced at the start of the pandemic. The first is people. Businesses had to make sure their employees as well as their customers were safe. They had to consider the protocols and
                            the expenses related to healthcare. Second is logistics. Companies had to carry on maintaining overhead costs, the facilities, rentals, even
                            transportation. The third is the actual product and service. Industries with products and services that could not adapt well had to scale back on
                            their product line or stop their services.</p>
                </a>
                <a className="naa-item"  href='/media/what-is-cloud-office-and-is-it-necessary'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article15-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">What is Cloud Office and Is It Necessary?</h2>
                    <p className="naa-preview">Cloud office and its importance to any business. Many companies have resorted to shifting to a cloud office in this ever-changing work landscape. But
                            what exactly is a cloud office, and is it necessary for businesses to change to a cloud office? What
                            are the benefits it will give the company? Time to shift to Cloud Office</p>
                </a>
                <a className="naa-item"  href='/media/what-is-telephony-and-how-does-it-help-productivity'>
                    <div>
                        <span className="article-tag servicesspotlight">Service Spotlight</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article16-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">What is Telephony and How Does it Help Productivity?</h2>
                    <p className="naa-preview">You might have seen the word “telephony” several times and wondered what it is. Is telephony a new
                            concept? Would it really help with employee productivity? Telephony, by definition, is the use or operation of an apparatus to transmit sounds as electrical
                            signals. Traditionally, telephony is a field of technology that involves the electronic transmission of
                            voice between two distant parties.</p>
                </a>
                <a className="naa-item" href='/media/how-to-collaborate-productively-in-any-work-set-up'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article8-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">How to Collaborate Productively in Any Work Set-up</h2>
                    <p className="naa-preview">Productive team collaboration makes a business work. Collaboration is the key to a productive and continuous business environment. With remote work at the forefront of current work setups in most companies, collaboration is the key to a productive and continuous business. It is a sustainable workflow that can impair the in-office setup to a flexible working environment where every worker can thrive for business success.</p>
                </a>
                <a className="naa-item" href='/media/10-reasons-to-shift-to-cloud-office'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article6-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">10 Reasons to Shift to Cloud Office</h2>
                    <p className="naa-preview">Enjoy the benefits when you shift to the Cloud Office. There are a lot of misconceptions about shifting from legacy or on-premise to cloud communication systems. As a result, companies are left to settle with outdated solutions and cannot fully support business needs.</p>
                </a>
                <a className="naa-item" href='/media/4-useful-tips-to-make-a-hybrid-setup-work'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article1-img-a.jpg")} alt="" />
                    </div>
                    <h2 className="naa-title">4 Useful Tips to Make a Hybrid Setup Work</h2>
                    <p className="naa-preview">Make the hybrid work setup actually work. Hybrid work has created an environment where employees can work both onsite and remotely. With
                            the premise of providing a good work-life balance and better savings, a good majority of Filipino employees prefer hybrid work. companies still have reservations about this work setup, with the same survey indicating that only
                            a small percentage of companies are prepared for hybrid work.</p>
                </a>
                <a className="naa-item" href='/media/5-effective-ways-to-thrive-in-a-hybrid-work-environment'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article3-img-a.jpg")} alt="" />
                    </div>
                    <h2 className="naa-title">5 Effective Ways to Thrive in a Hybrid Work Environment</h2>
                    <p className="naa-preview">Thrive in your work even if you’re working remotely from home. One of the basic things that should be in place in a successful flexible work setup is a hybrid work
                        model. With a working framework and rulebook in place, it’s now time to find effective ways for
                        employees to thrive and remain productive while working remotely or in-office, especially as more
                            companies in the Asia-Pacific region are likely to have remote and flexible arrangements in the next
                        few years.</p>
                </a>
                <a className="naa-item" href='/media/5-essential-tools-to-use-for-hybrid-work'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article4-img-a.jpg")} alt="" />
                    </div>
                    <h2 className="naa-title">5 Essential Tools to use for Hybrid Work</h2>
                    <p className="naa-preview">Must-have tools for a productive hybrid work. A lot of companies have begun to shift to a flexible work setup. To ensure success for business
                            continuity, companies and employees must have specific tools to function well. But why do hybrid
                            workplaces need such tools? Workable tools that professionals can use both in-office and remotely
                            provides easier access to files and most importantly internal and external communication.</p>
                </a>
                <a className="naa-item" href='/media/8-essential-things-to-consider-when-transitioning-to-a-hybrid-work-setup'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article5-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">8 Essential Things to Consider when Transitioning to a Hybrid Work Setup</h2>
                    <p className="naa-preview">Make your transition to a hybrid work setup easier. A hybrid setup is one of the options companies are now choosing to do instead of going full-remote or full-onsite work.
                        There could be challenges to transitioning to a hybrid work setup, especially since several studies have shown that those who work remotely are
                            47% more productive than those working onsite.</p>
                </a>
                <a className="naa-item" href='/media/5-common-myths-about-hybrid-work'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span>
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article2-img-a.jpg")} alt="" />
                    </div>
                    <h2 className="naa-title">5 Common Myths about Hybrid Work</h2>
                    <p className="naa-preview">Flexible work has been around for years. It’s time to utilize it. Contrary to popular belief, hybrid work is not a new phenomenon just borne from the pandemic.In fact, remote and flexible working arrangements have been around for some time. It existed even before the pandemic — just on a smaller scale.</p>
                </a>
                <a className="naa-item" href='/media/benefits-of-a-hybrid-work-setup'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article7-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Benefits of a Hybrid Work Set-Up</h2>
                    <p className="naa-preview">The benefits of hybrid work for employers and employees. The future of the workforce is hybrid. Multiple studies have shown that happier with this type of setup. However, a lot of
                            companies and leaders are still on the fence about transitioning from full onsite and full remote to a
                            hybrid work setup.</p>
                </a>
                <a className="naa-item" href='/media/hybrid-future-and-fate-of-the-cloud-office'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article9-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Hybrid Future & Fate of the Cloud Office</h2>
                    <p className="naa-preview">The future is in the Cloud. Hybrid is the present and the future of a workplace. With labor groups, the government, and some
                        private organizations pledging their support for a flexible setup, it’s safe to say that it’s time to start
                        thinking about shifting to a cloud office – that is if your organization has yet to do so.</p>
                </a>
                <a className="naa-item" href='/media/importance-of-cloud-communications'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article14-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Importance of Cloud Communications</h2>
                    <p className="naa-preview">Advance technology such as cloud communication is important in a workplace. Navigating the complexities of technology is a daily struggle. However, this becomes easier every day
                            because as technology advances, so does user convenience. With the existence of the cloud,
                            everything that did not seem possible before became possible. Now, everything is accessible, stored,
                            and completely secure on the internet.</p>
                </a>
                <a className="naa-item" href='/media/what-is-hybrid-work-and-why-do-employees-prefer-it'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article11-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">What is hybrid work and why do employees prefer it?</h2>
                    <p className="naa-preview">Why do employees prefer a hybrid work setup? Remote work has taken over our lives for the past two years since the pandemic started. With quarantine protocols and lockdowns in place, a lot of employees had to forego their office desks and daily chit-chats with their colleagues...
                            to a more private and quiet work-from-home setup.</p>
                </a>
                <a className="naa-item" href='/media/what-should-i-look-for-in-a-voip-solution'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article17-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">What should I look for in a VoIP solution?</h2>
                    <p className="naa-preview">Communication is important for business continuity. A key part of maintaining business continuity is communication. With workplaces adopting hybrid and
                            remote work setups, VoIP systems become a necessity to ensure that a company is still doing
                            business as usual. Before choosing which VoIP to get, find out what are the necessary things to look
                            for in a VoIP solution.</p>
                </a>
                <a className="naa-item" href='/media/what-you-need-to-know-about-cloud-communications'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article18-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">What you need to know about Cloud Communications</h2>
                    <p className="naa-preview">Cloud communication is necessary in a business. All across the globe, businesses are changing drastically. A lot of organizations are now preferring
                            cloud options instead of traditional legacy systems when it comes to communication, file sharing, and
                            other business-related activities.</p>
                </a>
                <a className="naa-item" href='/media/why-sip-trunks-will-help-call-center-efficiency'>
                    <div>
                        <span className="article-tag tipsandadvice">Tips and Advice</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article19-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Why SIP Trunks will Help Call Center Efficiency</h2>
                    <p className="naa-preview">Call Centers need to shift to SIP Trunking There is always a need for efficiency in a business, but none so more than call centers. With high
                                demands in service, increasing costs, and other service-related issues, it is important to increase
                                efficiency. SIP Trunking helps provide the efficiency that a call center company needs with its VoIP
                                call capabilities..</p>
                </a>
                <a className="naa-item" href='/media/laguna-to-launch-centralized-hotline-number-for-easy-access-to-service-and-faster-government-response'>
                    <div>
                        <span className="article-tag newspr">News and PR</span>
                        <span className="article-date">October 4, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article21-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Laguna to launch centralized hotline number for easy access to service and faster government response</h2>
                    <p className="naa-preview">As part of its initiatives towards digital transformation, the provincial government of Laguna has taken its first step in becoming a Smart Province with the launch of a centralized hotline that will ensure easy access to the services for Lagunenses, whether emergency response, official business, public service and even for reports and inquiries.</p>
                </a>
                <a className="naa-item" href='/media/city-of-san-fernando-takes-technological-leap-with-a-unified-hotline-number'>
                    <div>
                        <span className="article-tag newspr">News and PR</span>
                        <span className="article-date">August 18, 2023</span>
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article20-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">City of San Fernando takes technological leap with a Unified Hotline Number</h2>
                    <p className="naa-preview">The city government of San Fernando in the province of Pampanga has introduced a Voice Connectivity Integration or One Hotline Number – an effective means of communication for residents of the city. Gur Lavi Corp. (GLC), a prominent provider of voice communication solutions in the nation, supplied the technology for this groundbreaking project in San Fernando City, utilizing their flagship product, TeLavi, an innovative all-in-one communication platform recognized as a trailblazer in the country.</p>
                </a>
                <a className="naa-item" href='/media/partnerships-to-enable-future-ready-businesses'>
                    <div>
                        <span className="article-tag newspr">News and PR</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article22-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">Partnerships to enable future-ready businesses</h2>
                    <p className="naa-preview">True to its mission of providing businesses with powerful yet affordable and customizable communication solutions, Gur Lavi Corp. (GLC) is on
                            track to reach greater heights as they announced new partnerships with some of the known and trusted global tech brands. The company started from humble beginnings almost a decade ago and has cemented its place as one of the fastest growing
                            telecommunications distributors in the Philippines. One of its flagship brands is TeLavi Cloud, considered to be the only one of its kind and an all-
                            in communication platform in the country today.</p>
                </a>
                <a className="naa-item" href='/media/telavi-cloud-centralizes-cardona-rizals-internet-and-voice-connectivity'>
                    <div>
                        <span className="article-tag newspr">News and PR</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article23-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">TeLavi Cloud centralizes Cardona Rizal’s internet and voice
                        connectivity to improve its public service and delivery of education</h2>
                    <p className="naa-preview">True to its mission of providing businesses with powerful yet affordable and customizable communication solutions, Gur Lavi Corp. (GLC) is on
                        track to reach greater heights as they announced new partnerships with some of the known and trusted global tech brands. The company started from humble beginnings almost a decade ago and has cemented its place as one of the fastest growing
                            telecommunications distributors in the Philippines. One of its flagship brands is TeLavi Cloud, considered to be the only one of its kind and an all-
                            in communication platform in the country today.</p>
                </a>
                <a className="naa-item" href='/media/the-future-of-hybrid-work'>
                    <div>
                        <span className="article-tag newspr">News and PR</span>
                        <span className="article-date">August 18, 2023</span> 
                    </div>
                    <div className='naa-image'>
                        <img src={require("../assets/images/article-img/article10-img-a.png")} alt="" />
                    </div>
                    <h2 className="naa-title">The Future of Hybrid Work</h2>
                    <p className="naa-preview">What’s in store with a flexible work setup? In the age of continuous innovation and connectivity, hybrid work brings a new perspective to every
                            company’s work setup. Remote work took center stage when the pandemic started. With the
                            increased vaccination initiatives, companies adopted a more flexible approach to work setups. In fact,
                            several IT and business leaders have called hybrid work the “permanent solution” for their businesses.</p>
                </a>
            </div> */}
        </section>
        </>
        </>
    );
}