import React, {useRef, useState, useEffect} from 'react';
import Loader from '../components/Loader';
import { Fade, Zoom } from "react-awesome-reveal";
import { useAppContext } from '../libs/contextLib';
import { useParams } from 'react-router';
import "../assets/styles/IndustrySolutions.scss";
import solutions001 from '../assets/images/industry-solutions-001.png';
import solutions002 from '../assets/images/industry-solutions-002.png';
import solutions003 from '../assets/images/industry-solutions-003.png';
import solutions004 from '../assets/images/industry-solutions-004.png';
import solutions005 from '../assets/images/industry-solutions-005.png';
import plus from '../assets/images/industry-solutions-plus.png';
import minus from '../assets/images/industry-solutions-minus.png';
import dbquote1 from '../assets/images/industry-solutions-dbquote-001.png';
import dbquote2 from '../assets/images/industry-solutions-dbquote-002.png';
import left from '../assets/images/industry-solutions-left.png';
import right from '../assets/images/industry-solutions-right.png';

import bannertile from "../assets/images/inner-page-tile.png"

export default function IndustriesSolutions() {
    const {isPageLight, setIsPageLight} = useAppContext();
    const [loading, setLoading] = useState(true);
    const params= useParams();

    const [articleContent, setArticleContent] = useState();
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);

    const toggleSection1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleSection2 = () => {
        setIsOpen2(!isOpen2);
    };

    const toggleSection3 = () => {
        setIsOpen3(!isOpen3);
    };

    const toggleSection4 = () => {
        setIsOpen4(!isOpen4);
    };

    const toggleSection5 = () => {
        setIsOpen5(!isOpen5);
    };

    useEffect(() => {
        // setIsPageLight(false);
        setArticleContent(
            <div className='product-main product-ctc'>
                <section className='banner inner-page'>
                    <div className="content-wrapper">
                        <div className="left">
                            <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                                <img src={bannertile} className='inner-banner-tile'/>
                            </Fade>
                            <div className="brand">
                                <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                                    <h1 className="title">
                                        Industry Solutions
                                    </h1>
                                </Fade>
                            </div>
                        </div>
                        <div className="right imgbanner-wrapper">
                            <Fade triggerOnce duration={1500}>
                                <img src={require("../assets/images/industry-solutions-nav-hero.png")} className='imgbanner'/>
                            </Fade>
                        </div>
                    </div>
                </section>
                <section className="ctc-slogan">
                    <h1 className='title IS-title'>Enabling players in different industries to elevate their performance</h1>
                    <div className="content-wrapper IS-content">
                        <div className='content-container'>
                            <div className={isOpen1 ? 'content invert-content' : 'content'}>
                                <div className='img-container'> 
                                    <img src={solutions003}></img>
                                </div>
                                <h1>GOVERNMENT</h1>
                                <p className='first-par'>Empower your city with fast and efficient services</p>
                                <p className={isOpen1 ? 'visible collapsible-par' : 'collapsible-par hidden'}>Digitize your city’s communications systems and processes with ease for a city that’s set for a thriving future.</p>
                                <button onClick={toggleSection1}>{isOpen1 ? <img className='minusbtn' src={minus}></img> : <img src={plus}></img>}</button>
                            </div>
                        </div>
                        <div className='content-container'>
                            <div className={isOpen2 ? 'content invert-content' : 'content'}>
                                <div className='img-container'> 
                                    <img src={solutions002}></img>
                                </div>
                                <h1>RETAIL</h1>
                                <p className='first-par'>Improve customer experience while maximizing profit generation</p>
                                <p className={isOpen2 ? 'visible collapsible-par' : 'hidden'}>Customize your CX journey through our endless solutions that will push your business to greater heights.</p>
                                <button onClick={toggleSection2}>{isOpen2 ? <img className='minusbtn' src={minus}></img> : <img src={plus}></img>}</button>
                            </div>
                        </div>
                        <div className='content-container'>
                            <div className={isOpen3 ? 'content invert-content' : 'content'}>
                                <div className='img-container'> 
                                    <img src={solutions004}></img>
                                </div>
                                <h1>LOGISTICS</h1>
                                <p className='first-par'>Optimize your business' supply chain processes through the power of digital</p>
                                <p className={isOpen3 ? 'visible collapsible-par' : 'hidden'}>We can help implement systems that will lead to smoother operations for your business from procurement, production, sales and beyond.</p>                                
                                <button onClick={toggleSection3}>{isOpen3 ? <img className='minusbtn' src={minus}></img> : <img src={plus}></img>}</button>
                            </div>
                        </div>
                        <div className='content-container'>
                            <div className={isOpen4 ? 'content invert-content' : 'content'}>
                                <div className='img-container'> 
                                    <img src={solutions001} className='healthcare-logo'></img>
                                </div>
                                <h1>HEALTHCARE</h1>
                                <p className='first-par'>Facilitate quick and convenient access to medical services for your patients.</p>
                                <p className={isOpen4 ? 'visible collapsible-par' : 'hidden'}>Integrate seamless communication systems according to your needs and make your brand of healthcare more accessible and convenient.</p>
                                <button onClick={toggleSection4}>{isOpen4 ? <img className='minusbtn' src={minus}></img> : <img src={plus}></img>}</button>
                            </div>
                        </div>
                        <div className='content-container'>
                            <div className={isOpen5 ? 'content invert-content' : 'content'}>
                                <div className='img-container'> 
                                    <img src={solutions005}></img>
                                </div>
                                <h1>OTHER BUSINESSES</h1>
                                <p className='first-par'>Navigate the digital age with us. We can help you.</p>
                                <p className={isOpen5 ? 'visible collapsible-par' : 'hidden'}>Through digitization, implement systems that will lead to smoother processing and transactions.</p>
                                <button onClick={toggleSection5}>{isOpen5 ? <img className='minusbtn' src={minus}></img> : <img src={plus}></img>}</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="success IS-success">
                <div className="content-wrapper">
                    <Fade cascade direction='down' triggerOnce duration={500}>
                        <h1 className='title'>Our Successes</h1>
                    </Fade>
                    <div className="success-card">
                        <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                            <img src={require("../assets/images/industry-solutions-tile-002.png")}/>
                        </Fade>
                        <Fade triggerOnce direction='right' duration={1000} className='tile tile-br'>
                            <img src={require("../assets/images/industry-solutions-tile-003.png")}/>
                        </Fade>
                        <div className="opening-quote">
                            <Fade triggerOnce direction='left' duration={500}>&lsquo;&lsquo;</Fade>
                        </div>
                        <div className="quote">
                            <Fade triggerOnce direction='up' duration={500} cascade>
                                <p className=''>With #LAGUNA, residents of Laguna only need to know one number to call to be able to reach the agencies and the offices of the provinces.</p>

                                <a href='/media/laguna-to-launch-centralized-hotline-number-for-easy-access-to-service-and-faster-government-response' className="btn btn-black">Read More</a>
                            </Fade>
                        </div>
                        <div className="closing-quote">
                            <Fade triggerOnce direction='right' duration={500}>&rsquo;&rsquo;</Fade>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
        setLoading(false);
    }, [isOpen1, isOpen2, isOpen3, isOpen4, isOpen5]);


    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        {articleContent}
        </>
        </>
    );
}